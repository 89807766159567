import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';

const LoginTeacher = (props) => {
	let {
		handleInput,
		handleLogin,
		setTeacherBoxType,
		email,
		password,
		feedback,
		isLoading,
		invalidInputEmail,
		invalidInputPassword
	} = props;

	return (
		<div className="LoginTeacher">
			<form className="Login-form" onSubmit={handleLogin}>
				<input
					className={invalidInputEmail ? 'invalid' : ''}
					name="email"
					type="text"
					placeholder={loginUiTexts.email}
					autoComplete="section-teacherlogin email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					className={invalidInputPassword ? 'invalid' : ''}
					name="password"
					type="password"
					placeholder={loginUiTexts.password}
					autoComplete="section-teacherlogin password"
					value={password ? password : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input 
					className={'Button Button--login' + (isLoading ? ' Button--isLoading' : '')}
					type="submit"
					value={loginUiTexts.login}
				/>
				<p className="Login-errorMessage">{feedback}</p> 
			</form>
			<div 
				className="Login-resetPasswordBtn"
				onClick={()=>{setTeacherBoxType('resetPassword');}}
			>{loginUiTexts.forgotPassword}</div>
		</div>
	);
};

LoginTeacher.propTypes = {
	handleLogin: PropTypes.func.isRequired,
	setTeacherBoxType: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	invalidInputEmail: PropTypes.bool.isRequired,
	invalidInputPassword: PropTypes.bool.isRequired
};

export default LoginTeacher;