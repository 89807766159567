const errorTexts = {
	unknownError: 'Noget gik galt. Prøv venligst igen.',

	/* Create user (teacher) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',

	/* Login / reset password (teacher) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	
	/* Login (student) */
	noGameCodeMatch: 'Ugyldig spilkode.',

	/* Create game (teacher) */
	themeNotChosen: 'Du skal vælge mindst ét tema for at oprette en undervisningsforløb.',
};

export default errorTexts;