const loginUiTexts = {
	login: 'Log ind',
	logout: 'Log ud',
	teacher: 'Lærer',
	student: 'Elev',
	email: 'e-mail',
	password: 'password',
	passwordRepeat: 'gentag password',
	gameCode: 'Spilkode',
	createNewUser: 'Opret ny bruger',
	forgotPassword: 'Glemt dit password?',
	goToLoginBtn: 'Tilbage til login',
	resetPassword: 'Indtast din e-mail for at nulstille dit password:',
	createUserBtn: 'Opret',
	create: 'Opret',
	reset: 'Nulstil',
	cancel: 'Afbryd',
	cancel2: 'Annuller',
	createUserFeedback: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
	passwordResetFeedback: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
	unknownError: 'Fejl: ukendt fejl',
	error: 'Fejl',
	cookiesPopup: {
		text: 'Vi bruger cookies til at forbedre din spiloplevelse og for at hente spilrelateret data, som er tilknyttet hver enkelt konto. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
		more: 'Læs mere',
		btn: 'Ok',
	}
};

const generalUiTexts = {
	title: 'Den Digitale Gris',
	quizzes: 'Quizzer',
	didYouKnow: 'Noget at tygge på',
	// didYouKnow: 'Vidste du at...'
};

const settingsUiTexts = {
	loadingGame: 'Henter spil',
	gameExists: 'Du har allerede et spil i gang. <br/> Vil du starte et nyt spil, eller fortsætte med det gamle?',
	gameExistsInfo1: 'Nu har du dannet en spilkode. Del koden med eleverne. Fx denne skærm på et smartboard.',
	gameExistsInfo2: 'Hvis du selv vil spille spillet, så åben en anden browser eller en browser i incognito og indtast selv spilkoden.',
	continueGame: 'Fortsæt spil',
	startNewGame: 'Start nyt spil',
	createNewGame: 'Opret undervisningsforløb',
	chooseThemes: 'Vælg temaer som eleverne skal arbejder med',
	themes: 'Temaer',
	level: 'Niveau',
	easy: 'Let',
	difficult: 'Svær',
	gameCode: 'Spilkode',
};

const challengeUiTexts = {
	ready: 'Klar',
	start: 'Start',
	playAgain: 'Spil igen',
	back: 'Tilbage',
	choose: 'Vælg',
	answers: 'svar',
	next: 'Næste',
	next2: 'Videre',
	hintTitle: 'Hint',
	getAHint: 'Få et <span>hint</span>',
	getAnotherHint: 'Få et <span>hint</span> mere',
	viewDrawing: 'Se tegning',
	viewImage: 'Se billede',
	orderInstruction: 'Sæt tingene i rækkefølge:',
	getCluePopup: {
		title: 'OBS!',
		text: 'Jo flere hints du får, jo færre point får du.<br />Vil du have et hint?',
		buttons: {ok: 'Ja', cancel: 'Nej'}
	},
	newPointTierPopup: {
		title: 'Godt gået!',
		text: 'Du har nu fået point nok til at blive lige så cool som ',
		buttons: {ok: 'Ok'}
	},
	streakPopup: {
		title: 'Godt gået!',
		text: '%answers% rigtige svar i træk:',
		text2: 'Og du har nu fået point nok til at blive lige så cool som ',
		buttons: {ok: 'Videre'}
	},	
	gameCompletedPopup: {
		title: 'Score: %points%p',
		text: 'Tillykke. Nu er du nået til rejsens ende.<br /><br />Gå til dagens topscorerliste for at se, hvordan du har klaret dig.'
	},
	points: 'Point',
	themeCompleted: [
		{
			title: 'Godt gået!',
			text: 'Du svarede på alle spørgsmålene og fik 1 grisehale!<br />Prøv igen og se om du kan få endnu flere næste gang.'
		},
		{
			title: 'Godt gået!',
			text: 'Du svarede på alle spørgsmålene og fik 2 grisehaler!<br />Prøv igen og se om du kan få endnu flere næste gang.'
		},
		{
			title: 'Godt gået!',
			text: 'Du svarede på alle spørgsmålene og fik 3 grisehaler!<br />Prøv igen og se om du kan få endnu flere næste gang.'
		},
		{
			title: 'Fantastisk klaret!',
			text: 'Du svarede på alle spørgsmålene og fik 4 grisehaler!<br />Gå tilbage og prøv en af de andre quizzer!'
		}
	],
	allThemesCompleted: 'Tillykke! Du har løst alle opgaver i spillet.'
}

module.exports = {
	loginUiTexts,
	generalUiTexts,
	settingsUiTexts,
	challengeUiTexts
};
