/* TEMPLATE
{
	id: '',
	type: 'sort',
	themeId: null,
	challengeNumber: null,
	hint: {
		location: '',
		topic: '',
		subtopic: 0,
		popup: '',
		isInfoBoxText: false
	},
	question: {
		text: ''
	},
	boxes: [
		{id: '', name: ''},
		{id: '', name: ''},
	],
	items: [
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''}
	]
},
*/

const sortData = [
	{
		id: 'sort-1',
		type: 'sort',
		themeId: 1,
		challengeNumber: 8,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			popup: 'video',
			isInfoBoxText: false
		},
		question: {
			text: 'Hvad ville slagtereleven Lasse fremhæve, hvis han skulle anbefale andre at arbejde i kødbranchen?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: '"Hvis han godt kan lide at bruge sine fingre og lide at stå tidligt op, så er slagter også noget for ham"', isPlaced: false, correctBoxId: 'true'},
			{id: '2', name: '"Hvis han godt kan lide at lave et vigtigt stykke håndværk hver dag, er slagter også noget for ham"', isPlaced: false, correctBoxId: 'false'},
		]
	},
	{
		id: 'sort-2',
		type: 'sort',
		themeId: 1,
		challengeNumber: 9,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 2,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvorfor er der skrappe krav til hygiejnen på slagteriet?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Dårlig fødevarehygiejne på slagteriet kan gøre forbrugeren syg og skade forretningen', isPlaced: false, correctBoxId: 'true'},
			{id: '2', name: 'Alle forbrugere skal kunne føle sig trygge, når de spiser produkterne', isPlaced: false, correctBoxId: 'true'},
		]
	},
	{
		id: 'sort-4',
		type: 'sort',
		themeId: 2,
		challengeNumber: 7,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: null,
			popup: 'image',
			isInfoBoxText: false,
			isFunFact: false
		},
		question: {
			text: 'Hvor eksporteres grisens hoved til?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Japan', image: 'japan-flag.png', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'Kina', image: 'china-flag.png', isPlaced: false, correctBoxId: 'true'},
			{id: '3', name: 'Korea', image: 'korea-flag.png', isPlaced: false, correctBoxId: 'false'},
		]
	},
	{
		id: 'sort-5',
		type: 'sort',
		themeId: 3,
		challengeNumber: 2,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 2,
			subtopic2: 3,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvad gælder for både frilands grise og økologiske grise?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Smågrise kommer til verden i hytter', isPlaced: false, correctBoxId: 'true'},
			{id: '2', name: 'De må få genmodificeret mad', isPlaced: false, correctBoxId: 'false'},
			{id: '3', name: 'Smågrisene bliver hos deres mor til de er 5 uger gamle', isPlaced: false, correctBoxId: 'false'},
		]
	},
	// {
	// 	id: 'sort-6',
	// 	type: 'sort',
	// 	themeId: 6,
	// 	challengeNumber: 3,
	// 	hint: {
	// 		location: 'butchery',
	// 		topic: 'head',
	// 		subtopic: null,
	// 		popup: null,
	// 		isInfoBoxText: true,
	// 		isFunFact: false
	// 	},
	// 	question: {
	// 		text: 'På restauranter i Kina spises grisehjernen som en delikatesse?'
	// 	},
	// 	boxes: [
	// 		{id: 'true', name: 'Sandt'},
	// 		{id: 'false', name: 'Falskt'},
	// 	],
	// 	items: [
	// 		{id: '1', name: 'Sandt', isPlaced: false, correctBoxId: 'true'},
	// 		{id: '2', name: 'Falsk', isPlaced: false, correctBoxId: 'false'},
	// 	]
	// },
	// {
	// 	id: 'sort-7',
	// 	type: 'sort',
	// 	themeId: 7,
	// 	challengeNumber: 5,
	// 	hint: {
	// 		location: 'consumer',
	// 		topic: 'detail',
	// 		subtopic: 2,
	// 		subtopic2: 2,
	// 		popup: 'video',
	// 		isInfoBoxText: false,
	// 		isFunFact: false
	// 	},
	// 	question: {
	// 		text: 'Opskriften på frikadellen mødes første gang i kogebogen "En høyfornemme Madames Kaagebog" fra år 1704'
	// 	},
	// 	boxes: [
	// 		{id: 'true', name: 'Sandt'},
	// 		{id: 'false', name: 'Falskt'},
	// 	],
	// 	items: [
	// 		{id: '1', name: 'Sandt', isPlaced: false, correctBoxId: 'true'},
	// 		{id: '2', name: 'Falsk', isPlaced: false, correctBoxId: 'false'},
	// 	]
	// },
	{
		id: 'sort-8',
		type: 'sort',
		themeId: 8,
		challengeNumber: 3,
		hint: {
			location: 'education',
			topic: 'cleanser',
			subtopic: null,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvad lærer man på tarmrenseruddannelsen?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Produktion af svinefoder', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'At konservere og pakke tarmene', isPlaced: false, correctBoxId: 'true'},
			{id: '3', name: 'At rense og sortere tarmene', isPlaced: false, correctBoxId: 'true'},
			{id: '4', name: 'Hygiejne og kvalitetskontrol', isPlaced: false, correctBoxId: 'true'},
			{id: '5', name: 'Fødevarehistorie', isPlaced: false, correctBoxId: 'false'},
		]
	},
	{
		id: 'sort-9',
		type: 'sort',
		themeId: 8,
		challengeNumber: 4,
		hint: {
			location: 'education',
			topic: 'cleanser',
			subtopic: 3,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Rensning af tarmene foregår hovedsageligt ved hjælp af '
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Håndkraft', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'Maskiner', isPlaced: false, correctBoxId: 'true'},
			{id: '3', name: 'Kemikalier', isPlaced: false, correctBoxId: 'false'},
		]
	},
	{
		id: 'sort-10',
		type: 'sort',
		themeId: 8,
		challengeNumber: 7,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 4,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilke arbejdsopgaver har en industrioperatør ansvaret for?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Ansvaret for slagtning af grise', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'Ansvaret for daglig drift og vedligeholdelsen af produktionsanlæg.', isPlaced: false, correctBoxId: 'true'},
			{id: '3', name: 'Ansvar for at produkterne er af god kvalitet og produceres forsvarligt for miljø og klima.', isPlaced: false, correctBoxId: 'true'},
			{id: '4', name: 'Ansvaret for rensning af grisetarme og grisemaver.', isPlaced: false, correctBoxId: 'false'},
		]
	},
	{
		id: 'sort-11',
		type: 'sort',
		themeId: 8,
		challengeNumber: 9,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 1,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'En slagters hverdag er kendetegnet ved?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Kreativ problemløsning, samarbejde og mange skift', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'Ventetid, tålmodighed og fysisk arbejde', isPlaced: false, correctBoxId: 'false'},
			{id: '3', name: 'Struktur, tempo og forudsigelighed ', isPlaced: false, correctBoxId: 'true'},

		]
	},
	{
		id: 'sort-12',
		type: 'sort',
		themeId: 8,
		challengeNumber: 11,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 3,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilke 7 genstande er en del af slagterens sikkerhedsudstyr?'
		},
		boxes: [
			{id: 'true', name: 'Sandt'},
			{id: 'false', name: 'Falskt'},
		],
		items: [
			{id: '1', name: 'Sikkerhedsbriller', isPlaced: false, correctBoxId: 'false'},
			{id: '2', name: 'En bolero', isPlaced: false, correctBoxId: 'true'},
			{id: '3', name: 'Høreværn', isPlaced: false, correctBoxId: 'true'},
			{id: '4', name: 'Skære-handske', isPlaced: false, correctBoxId: 'true'},
			{id: '5', name: 'Sikkerhedssko', isPlaced: false, correctBoxId: 'false'},
			{id: '6', name: 'Wizard-handske', isPlaced: false, correctBoxId: 'true'},
			{id: '7', name: 'Forklæde', isPlaced: false, correctBoxId: 'true'},
			{id: '8', name: 'Visir', isPlaced: false, correctBoxId: 'false'},
			{id: '9', name: 'Hjelm', isPlaced: false, correctBoxId: 'true'},
			{id: '10', name: 'Et fingerbøl', isPlaced: false, correctBoxId: 'false'},
			{id: '11', name: 'Gummihandsker', isPlaced: false, correctBoxId: 'true'},



		]
	},
];

export {
	sortData
};