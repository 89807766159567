import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCYLWbt6seddkJCVUFPewxDmuXRFR12hM4',
		authDomain: 'cgl-digitalgris-test.firebaseapp.com',
		projectId: 'cgl-digitalgris-test',
		storageBucket: 'cgl-digitalgris-test.appspot.com',
		messagingSenderId: '806944710775',
		appId: '1:806944710775:web:b274146253b879c1b56672'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCSJqGKj7XjeaBiCFkA33AKkEc8lF590LQ',
		authDomain: 'cgl-digitalgris-production.firebaseapp.com',
		projectId: 'cgl-digitalgris-production',
		storageBucket: 'cgl-digitalgris-production.appspot.com',
		messagingSenderId: '664462244996',
		appId: '1:664462244996:web:47f0271cbcd72be986d6d1',
		measurementId: 'G-JK6EZ5VDN6'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
if (env === 'production') {
	firebase.analytics();
}

export default firebase.firestore;