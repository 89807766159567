import themesData from 'data/themes-data';
import {sortArrayByProperty} from './array-helper';

/**
 * Check if player has completed all themes
 * @param {array} challengesData
 * @param {object} playerData
 */
export function areAllThemesCompleted(challengesData, playerData) {
	let allThemesCompleted = false;

	let numberOfCompletedThemes = 0;
	themesData.forEach((theme) => {
		let themeChallengesData = challengesData.filter((challenge) => {return challenge.themeId === theme.id;});
		let themeIsCompleted = isThemeCompleted(themeChallengesData, playerData);
		if (themeIsCompleted) numberOfCompletedThemes = numberOfCompletedThemes + 1;			
	});

	if (numberOfCompletedThemes === themesData.length) allThemesCompleted = true;

	return allThemesCompleted;
}

/**
 * Check if player has completed theme
 * @param {array} themeChallengesData
 * @param {object} playerData
 */
export function isThemeCompleted(themeChallengesData, playerData) {
	let themeIsCompleted = false;

	if (themeChallengesData.length <= 0) {
		/* No challenges in theme */
		themeIsCompleted = true;
	} else {
		/* Check last challenge of theme */
		let themeChallengesDataSorted = sortArrayByProperty(themeChallengesData, 'challengeNumber', 'ASC');
		let lastChallengeId = themeChallengesDataSorted[themeChallengesData.length - 1].id;
		let playerChallengeIndex = playerData.challenges.findIndex((challenge) => {
			return challenge.id === lastChallengeId;
		});
		themeIsCompleted =
			playerChallengeIndex >= 0 &&
			playerData.challenges[playerChallengeIndex].hasOwnProperty('completed') &&
			playerData.challenges[playerChallengeIndex].completed === true
		;
	}

	return themeIsCompleted;
}

/*
 * Get status of each challenge (ready / completed / failed)
 * @param {array} themeChallengesData
 * @param {object} playerData
*/
export function getChallengesStatus(themeChallengesData, playerData) {
	return themeChallengesData.map((challengeData) => {
		let status = 'ready';
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.completed) {
				if (playerChallengeData.points > 0) {
					status = 'completed';
				} else {
					status = 'failed';
				}
			}
		}
		challengeData.status = status;
		return challengeData;
	});
}
