const medals = ['gold', 'silver', 'bronze'];

const icons = [
	{pointsMax: 20, id: 'none', text: '',},
	{pointsMin: 21, pointsMax: 40, id: 'dikdik', text: 'en dikdik'},
	{pointsMin: 41, pointsMax: 60, id: 'ant-eater', text: 'en myresluger'},
	{pointsMin: 61, pointsMax: 85, id: 'rhino', text: 'et næsehorn'},
	{pointsMin: 86, pointsMax: 115, id: 'walrus', text: 'en hvalros'},
	{pointsMin: 116, pointsMax: 140, id: 'emperor-penguin', text: 'en kejserpingvin'},
	{pointsMin: 141, pointsMax: 160, id: 'orangutang', text: 'den kloge orangutang'},
	{pointsMin: 161, id: 'snow-leopard', text: 'en sneleopard'}
];

export {
	medals,
	icons
};