/* Sort array of objects by specific object property */
export function sortArrayByProperty(array, propertyName, order = 'ASC', 
	secondaryPropertyName = null, secondaryOrder = 'ASC',
	tertiaryPropertyName = null, tertiaryOrder = 'ASC'
) {
	let sortedArray = array.sort(function(a, b) {
		/* The value of the primary properties are the same */
		if (a[propertyName] === b[propertyName] && secondaryPropertyName) {
			/* Sort by secondary property if given and both objects have it */
			if (
				a.hasOwnProperty(secondaryPropertyName) && a[secondaryPropertyName] &&
				b.hasOwnProperty(secondaryPropertyName) && b[secondaryPropertyName]) {
				if (secondaryOrder === 'ASC') return a[secondaryPropertyName] - b[secondaryPropertyName];
				return b[secondaryPropertyName] - a[secondaryPropertyName];
			}
			/* Sort by secondary property if on of the objects have it */
			if (
				(a.hasOwnProperty(secondaryPropertyName) && a[secondaryPropertyName]) || 
				(b.hasOwnProperty(secondaryPropertyName) && b[secondaryPropertyName])
			) {
				if (a.hasOwnProperty(secondaryPropertyName) && a[secondaryPropertyName]) {
					if (secondaryOrder === 'ASC') return -1;
					return 1;
				}
				if (secondaryOrder === 'ASC') return 1;
				return -1;
			}
			/* Sort by tertiery property if given and both objects have it */
			if (tertiaryPropertyName !== null &&
				a.hasOwnProperty(tertiaryPropertyName) && b.hasOwnProperty(tertiaryPropertyName)		
			) {
				if (tertiaryOrder === 'ASC') return a[tertiaryPropertyName] - b[tertiaryPropertyName];
				return b[tertiaryPropertyName] - a[tertiaryPropertyName];
			}
		}

		/* Sort by primary property */
		if (order === 'ASC') return a[propertyName] - b[propertyName];
		else if (order === 'DESC') return b[propertyName] - a[propertyName];
		throw Error('order must be ASC or DESC');
	});
	return sortedArray;
};

// Randomly shuffle array
export function shuffleArray(array) {
	if (array.length < 2) {return array;}
	// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
	return array.map((a) => { return [Math.random(), a];})
		.sort((a, b) => { return a[0] - b[0];})
		.map((a) => { return a[1];});
}