import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import {pigCuts} from 'data/pig-data';
import './pig.scss';

const Pig = ({location, topic, selectTopic, animatePig, animatePigOnce, animatePigCut, hint, freezeScreen}) => {
	let showPigSubcuts = topic && pigCuts.findIndex((cut) => {return cut.id === topic.id;}) > -1;
	let showPigCuts = !topic;
	/**
	 * keep track of prev topic selected for animation backwards
	 */
	const [prevTopic, setPrevTopic] = useState('');
	
	if (location.id === 'butchery') {
		return (
			<div className={'Pig '
				+ (showPigSubcuts ? 'Pig--showSubcuts' : '')
				+ (showPigCuts ? 'Pig--showCuts' : '')
				+ (freezeScreen ? ' freeze' : '')
				+ (animatePig ? ' animate' : '')
				+ (animatePigOnce ? ' animateOnce' : '')}
			onClick={() => {
				setPrevTopic(topic);
				setTimeout(() => {showPigSubcuts && selectTopic(null);}, 250); // 250);
			}}>
				<div className="Pig-cuts">
					{pigCuts.map((cut, i) => {
						let isSelected = topic && topic.id === cut.id;
						let isUnSelected = prevTopic && prevTopic.id === cut.id;
						return <div key={i} className={'Pig-cutWrap'
							+ (isSelected ? ' selected' : '' )
							+ (isUnSelected ? ' unSelected' : '')
							+ (animatePigCut && hint && hint.topic === cut.id ? ' animate' : '')}>
							<ReactSVG
								className={'Pig-cut Pig-cut--' + cut.id}
								onClick={() => {showPigCuts && selectTopic(cut);}}
								src={require('assets/images/' + cut.image).default}
							/>
							<ReactSVG
								className={'Pig-cut Pig-cut--zoomed Pig-cut--' + cut.id}
								onClick={() => {showPigCuts && selectTopic(cut);}}
								src={require('assets/images/' + cut.image.split('.')[0] + '-zoomed.svg').default}
							/>
							
						</div>;
					})}
				</div>
			</div>
		);
	}
	return null;
};

Pig.propTypes = {
	location: PropTypes.object.isRequired,
	topic: PropTypes.object,
	selectTopic: PropTypes.func,
	animatePigCut: PropTypes.bool,
	animatePig: PropTypes.bool,
	animatePigOnce: PropTypes.bool,
	hint: PropTypes.object,
	freezeScreen: PropTypes.bool.isRequired,
};

export default Pig;