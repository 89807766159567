import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import './theme-progress.scss';

const ThemeProgress = (props) => {
	let {
		themeChallenges,
		currentChallengeIndex,
		goToChallenge
	} = props;

	return (
		<div className="ThemeProgress">
			{themeChallenges.map((challenge, index) => {
				let className = 'ThemeProgress-step ThemeProgress-step--' + challenge.status;
				if (index === currentChallengeIndex) className = className + ' ThemeProgress-step--selected';
				let canNavigateTo = false;
				if ((appConfig.env === 'development' || appConfig.env === 'test')) canNavigateTo = true;
				return (
					<div
						key={index}
						className={className}
						onClick={()=>{if (canNavigateTo && goToChallenge) goToChallenge(index);}}
					/>
				);
			})}
		</div>
	);
};

ThemeProgress.propTypes = {
	themeChallenges: PropTypes.array.isRequired,
	goToChallenge: PropTypes.func,
	currentChallengeIndex: PropTypes.number
};

export default ThemeProgress;