import React from 'react';
import PropTypes from 'prop-types';
import {challengeUiTexts} from 'data/ui-texts';
import ChallengeController from 'components/challenges/challenge-controller';
import ThemeProgress from 'components/theme-progress/theme-progress';
import ThemePoints from 'components/theme-points/theme-points';
import {getPointsIllustrationData} from 'helpers/points-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './theme.scss';

const Theme = (props) => {
	let {
		showThemeIntroContent,
		showThemeCompletedContent,
		themeData,
		themeChallengesData,
		playerData,
		goToTheme,
		updatePlayerData,
		handleThemeComplete,
		startTheme,
		resetTheme,
		showHint,
		setHint
	} = props;

	let {greenFilling, filledDivisions} = getPointsIllustrationData(themeChallengesData, playerData);

	let themeCompletedFeedback = challengeUiTexts.themeCompleted[filledDivisions.lastIndexOf(true)];
	let pigImageClassName = 'Theme-pigImage--bronze';
	if (filledDivisions.lastIndexOf(true) === 1 || filledDivisions.lastIndexOf(true) === 2) {
		pigImageClassName = 'Theme-pigImage--silver';
	}
	if (filledDivisions.lastIndexOf(true) === 3) {
		pigImageClassName = 'Theme-pigImage--gold';
	}

	if (showThemeIntroContent) {
		return (
			<div className="Theme">
				<ThemePoints greenFilling={greenFilling} filledDivisions={filledDivisions}/>
				<div className="Theme-name">{themeData.name}</div>
				<ThemeProgress themeChallenges={themeChallengesData} goToChallenge={null} currentChallengeIndex={null}/>
				<div className="Theme-intro">{themeData.intro}</div>
				<div className="Theme-btnWrap">
					<div className="Theme-backBtn" onClick={() => {goToTheme(null);}}>{challengeUiTexts.back}</div>
					<div className="Theme-startBtn" onClick={() => {startTheme();}}>{challengeUiTexts.start}</div>
				</div>
			</div>
		);
	}

	if (showThemeCompletedContent) {
		return (
			<div className="Theme">
				<ThemePoints greenFilling={greenFilling} filledDivisions={filledDivisions}/>
				<div className="Theme-name">{themeData.name}</div>
				<ThemeProgress themeChallenges={themeChallengesData} goToChallenge={null} currentChallengeIndex={null}/>
				<div className="Theme-intro">
					<span className="Theme-introTitle">{themeCompletedFeedback && themeCompletedFeedback.title}</span>
					{themeCompletedFeedback && renderMarkdown(themeCompletedFeedback.text)}
				</div>
				<div className={'Theme-pigImage ' + pigImageClassName}/>
				<div className="Theme-backBtn" onClick={() => {goToTheme(null);}}>{challengeUiTexts.back}</div>
				<div className="Theme-startBtn" onClick={() => {resetTheme(null);}}>{challengeUiTexts.playAgain}</div>
			</div>
		);
	}

	return (
		<div className="Theme">
			<ThemePoints greenFilling={greenFilling} filledDivisions={filledDivisions}/>
			<ChallengeController
				themeData={themeData}
				themeChallengesData={themeChallengesData}
				playerData={playerData}
				updatePlayerData={updatePlayerData}
				handleThemeComplete={handleThemeComplete}
				setHint={setHint}
			/>
			<div className="Theme-backBtn" onClick={() => {goToTheme(null);}}>{challengeUiTexts.back}</div>
			<div className="Theme-hintBtn" onClick={() => {showHint();}}>{challengeUiTexts.hintTitle}</div>
		</div>
	);
};

Theme.propTypes = {
	showThemeIntroContent: PropTypes.bool.isRequired,
	showThemeCompletedContent: PropTypes.bool.isRequired,
	themeData: PropTypes.object.isRequired,
	themeChallengesData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	goToTheme: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleThemeComplete: PropTypes.func.isRequired,
	startTheme: PropTypes.func.isRequired,
	resetTheme: PropTypes.func.isRequired,
	showHint: PropTypes.func.isRequired,
	setHint: PropTypes.func.isRequired
};

export default Theme;