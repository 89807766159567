import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import apiHelper from 'helpers/api-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import LoginStudent from './login-student';
import appConfig from 'config/app.config';

class LoginStudentController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			students: null,
			feedback: null,
			isLoading: false,
			invalidGameCode: false,
			studentId: null,
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			invalidGameCode: false
		});
	}

	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event
	 */
	handleFindGame = (event) => {
		event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({
				invalidGameCode: true,
				feedback: errorTexts[errorFeedbackHelper('empty-fields')],
			});
			return;
		}
		this.setState({isLoading: true});
		const db = firebase.firestore();
		db.collection(appConfig.gamesDbName).doc(code).get().then((doc) => {
			if (doc.exists) {
				// Create new anonymous student account
				this.handleGetLoginToken();
			} else {
				const msg = errorTexts[errorFeedbackHelper('wrong-game-code')];
				this.setState({
					feedback: msg,
					isLoading: false,
					invalidGameCode: true
				});
			}
		}).catch((error) => {
			console.error(error);
			// const msg = errorTexts[errorFeedbackHelper('unknown-error')];
			const msg = 'Login error 1: ' + error.code;
			this.setState({
				feedback: msg,
				isLoading: false
			});
		});
	}


	/**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 */
	handleGetLoginToken = () => {
		this.setState({
			isLoading: true,
		});

		apiHelper('student/join-game', {gameCode: this.state.code}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					this.loginWithToken(response.token);
				} else {
					console.error(response);
					const msg = errorTexts[errorFeedbackHelper('unknown-error')];
					this.setState({
						feedback: msg,
						isLoading: false,
					});
				}
			},
			(rejection) => {
				this.setState({
					isLoading: false,
				});
				console.error('rejection: ', rejection);
			}
		);
	}


	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
				this.setState({
					isLoading: false,
					studentId: null
				});
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				const msg = errorTexts[errorFeedbackHelper('unknown-error')];
				this.setState({
					feedback: msg,
					isLoading: false,
					studentId: null
				});
			});
	}

	render = () => {
		return (
			<LoginStudent
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				code={this.state.code}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
				invalidGameCode={this.state.invalidGameCode}
			/>
		);
	}
}

export default LoginStudentController;
