import React, {Component} from 'react';
import PropTypes from 'prop-types';
import themesData from 'data/themes-data';
import {quizzesData} from 'data/quizzes-data';
import {orderData} from 'data/order-data';
import {sortData} from 'data/sort-data';
import {pairsData} from 'data/pairs-data';
import ThemeOverview from './theme-overview';

class ThemeOverviewController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			themeId: null,
			themes: []
		};
		this.timeout = null;
	}

	componentDidMount() {
		/* Load themes & challenges */
		let themes = themesData.filter((theme) => {return this.props.game.themeIds.includes(theme.id);});
		let challengesData = JSON.parse(JSON.stringify([...quizzesData, ...orderData, ...sortData, ...pairsData]));

		/* Sync with player progress */
		let userIsPlaying = false;
		themes.forEach((theme) => {
			theme.completionLevel = 0;
			let themeChallengesData = challengesData.filter((challengeData) => {
				return (
					challengeData.themeId === theme.id &&
					challengeData.challengeNumber && challengeData.challengeNumber >= 0
				);
			});
			if (
				themeChallengesData.length > 0 &&
				this.props.playerData.hasOwnProperty('challenges') && this.props.playerData.challenges.length > 0
			) {
				let completedChallenges = 0;
				themeChallengesData.forEach((challenge) => {
					let playerChallengeIndex = this.props.playerData.challenges.findIndex((challengeData) => {
						return challengeData.id === challenge.id;
					});
					if (playerChallengeIndex >= 0 && this.props.playerData.challenges[playerChallengeIndex].completed) {
						completedChallenges = completedChallenges + 1;
					}
				});
				theme.completionLevel = (completedChallenges / themeChallengesData.length).toFixed(2);
			}
			if (theme.completionLevel > 0 && !userIsPlaying) userIsPlaying = true;
		});

		/* Update state */
		this.setState({themes});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		clearTimeout(this.timeout);
	}

	goToTheme = (themeId) => {
		this.setState({themeId});
		/* Remove hint animations */
		this.props.setHint(null);
	}

	/**
	 * Render component
	 */
	render = () => {
		return (
			<ThemeOverview
				themes={this.state.themes}
				goToTheme={this.goToTheme}
				themeId={this.state.themeId}
				playerData={this.props.playerData}
				updatePlayerData={this.props.updatePlayerData}
				showHint={this.props.showHint}
				setHint={this.props.setHint}
			/>
		);
	}
}

ThemeOverviewController.propTypes = {
	playerData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	showHint: PropTypes.func.isRequired,
	setHint: PropTypes.func.isRequired
};

export default ThemeOverviewController;