import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './theme-points.scss';

const ThemePoints = (props) => {
	let {
		filledDivisions,
		greenFilling,
	} = props;

	let pigAudio = new Audio(require('../../assets/audio/pig-sound.ogg').default);
	let animate = false;
	let nrOfDivisions = 4;

	const prevRef = useRef();
	useEffect(() => {
		prevRef.current = filledDivisions;
	});
	const prevFilledDivisions = prevRef.current;

	if (prevFilledDivisions !== undefined
		&& filledDivisions.includes(true)
		&& !prevFilledDivisions.every((val, i) => {return val === filledDivisions[i];})
	) {
		animate = true;
		pigAudio.play();
	}
	
	return (
		<div className="ThemePoints">
			<div className="ThemePoints-background">
				<div className="ThemePoints-filling" style={{width: greenFilling + '%'}}/>
			</div>
			<div className="ThemePoints-divisions">
				{new Array(nrOfDivisions).fill(0).map((_, i) => {
					return <div key={i} className={'ThemePoints-division'
						+ (filledDivisions[i] ? ' ThemePoints-division--filled' : '')
						+ (filledDivisions[i] && animate ? ' ThemePoints-division--animate' : '')}
					/>;
				})}
			</div>
		</div>
	);
};

ThemePoints.propTypes = {
	greenFilling: PropTypes.number.isRequired,
	filledDivisions: PropTypes.array.isRequired,
};

export default ThemePoints;