import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import {generalUiTexts} from 'data/ui-texts';
import exploreData from 'data/explore-data';
import Pig from 'components/pig/pig';
import Popup from 'components/popup/popup';
import './explore.scss';
import {pigCuts, pigTopic, educationTopic} from 'data/pig-data';
import {renderMarkdown} from 'helpers/text-helper';

const Explore = ({
	location,
	topic,
	subtopic,
	subtopic2,
	selectLocation,
	selectTopic,
	selectSubtopic,
	selectSubtopic2,
	togglePopup,
	popup,
	hint,
	freezeScreen,
	infoBoxRef,
	recipePopupRef
}) => {

	let subtitle = '';
	if (!topic) subtitle = location.instruction;
	if (topic) subtitle += topic.name;
	if (topic && subtopic && pigCuts.findIndex((cut) => {return cut.id === topic.id;}) === -1) {
		subtitle = subtopic.name;
	}
	if (topic && subtopic && subtopic2) subtitle = subtopic2.name;

	let infoBoxText = null;
	if (!topic && location.id === 'butchery') infoBoxText = pigTopic.intro;
	if (!topic && location.id === 'education') infoBoxText = educationTopic.intro;
	if (topic) infoBoxText = topic.intro ? topic.intro : null;
	if (topic && subtopic) infoBoxText = subtopic.text;
	if (topic && subtopic && subtopic2) infoBoxText = subtopic2.text;

	let funFact = null;
	if (subtopic) {
		if (subtopic.funFact) funFact = renderMarkdown(subtopic.funFact);
		if (subtopic2) {
			if (subtopic2.funFact) {
				funFact = renderMarkdown(subtopic2.funFact);
			} else {
				funFact = null;
			}
		}
	} else {
		if (topic && topic.funFact) funFact = renderMarkdown(topic.funFact);
	}
	if (!topic && location.id === 'education') funFact = renderMarkdown(educationTopic.funFact);

	let popupImage = null;
	if (subtopic) {
		if (subtopic.image && !subtopic2) popupImage = subtopic.image;
		if (subtopic2) {
			if (subtopic2.image) {
				popupImage = subtopic2.image;
			} else {
				popupImage = null;
			}
		}
	} else {
		if (topic && topic.introImage) popupImage = topic.introImage;
		if (location.id === 'butchery' && !topic) popupImage = pigTopic.introImage;
	} 

	let popupImage2 = null;
	if (subtopic) {
		if (subtopic.image2 && !subtopic2) popupImage2 = subtopic.image2;
		if (subtopic2) {
			if (subtopic2.image2) {
				popupImage2 = subtopic2.image2;
			} else {
				popupImage2 = null;
			}
		}
	} else {
		if (topic && topic.introImage2) popupImage2 = topic.introImage2;
	}

	let popupVideo = null;

	if (subtopic) {
		if (subtopic.video) popupVideo = subtopic.video;
		if (subtopic2) {
			if (subtopic2.video) {
				popupVideo = subtopic2.video;
			} else {
				popupVideo = null;
			}
		}
	} else {
		if (topic && topic.introVideo) popupVideo = topic.introVideo;
	}

	let popupRecipe = null;
	if (subtopic) {
		if (subtopic.recipe) popupRecipe = subtopic.recipe;
		if (subtopic2) {
			if (subtopic2.recipe) {
				popupRecipe = subtopic2.recipe;
			} else {
				popupRecipe = null;
			}
		}
	} else {
		if (topic && topic.introRecipe) popupRecipe = topic.introRecipe;
	}

	let popupCuttingInstructions = null;
	if (subtopic) {
		if (subtopic.cuttingInstructions) popupCuttingInstructions = subtopic.cuttingInstructions;
		if (subtopic2) {
			if (subtopic2.cuttingInstructions) {
				popupCuttingInstructions = subtopic2.cuttingInstructions;
			} else {
				popupCuttingInstructions = null;
			}
		}
	} else {
		if (topic && topic.introCuttingInstructions) popupCuttingInstructions = topic.introCuttingInstructions;
	}

	let popupSustainability = null;
	if (subtopic) {
		if (subtopic.sustainability) popupSustainability = subtopic.sustainability;
		if (subtopic2) {
			if (subtopic2.sustainability) {
				popupSustainability = subtopic2.sustainability;
			} else {
				popupSustainability = null;
			}
		}
	} else {
		if (topic && topic.sustainability) popupSustainability = topic.sustainability;
	}

	let showRecipePopup = popup.show && popup.type === 'recipe';
	let showSustainabilityPopup = popup.show && popup.type === 'sustainability';
	let showVideoPopup = popup.show && popup.type === 'video';
	let showImagePopup = popup.show && popup.type === 'image';
	let showImage2Popup = popup.show && popup.type === 'image2';
	let showCuttingInstructionsPopup = popup.show && popup.type === 'cuttingInstructions';
	// eslint-disable-next-line max-len
	let showPopupIcons = popupVideo || popupImage || popupImage2 || popupCuttingInstructions || popupRecipe || popupSustainability;
	
	let isOnHintTopic = hint && topic && hint.topic === topic.id;
	let isOnHintSubtopic = isOnHintTopic && subtopic && subtopic.id === hint.subtopic;
	let isOnHintSubtopic2 = isOnHintSubtopic && subtopic2 && subtopic2.id === hint.subtopic2;

	let animateInfoBox = false;
	let animateFunFactBox = false;
	let animateVideoPopupIcon = false;
	let animateRecipePopupIcon = false;
	let animateImagePopupIcon = false;
	let animateImage2PopupIcon = false;
	let animateCuttingInstructionsPopupIcon = false;
	let animatesustainabilityPopupIcon = false;

	if (hint) {
		if (hint.subtopic) {
			if (hint.subtopic2) {
				animateInfoBox = hint.isInfoBoxText && isOnHintSubtopic2;
				animateFunFactBox = hint.isFunFact && isOnHintSubtopic2;
				animateVideoPopupIcon = hint.popup === 'video' && isOnHintSubtopic2 && !showVideoPopup;
				animateRecipePopupIcon = hint.popup === 'recipe' && isOnHintSubtopic2 && !showRecipePopup;
				animatesustainabilityPopupIcon = hint.popup === 'sustainability' && isOnHintSubtopic2 
					&& !showSustainabilityPopup;
				animateImagePopupIcon = hint.popup === 'image' && isOnHintSubtopic2 && !showImagePopup;
				animateImage2PopupIcon = hint.popup === 'image2' && isOnHintSubtopic2 && !showImage2Popup;
				animateCuttingInstructionsPopupIcon = hint.popup === 'cuttingInstructions' && isOnHintSubtopic2
					&& !showCuttingInstructionsPopup;
			} else {
				let subtopicOnly = isOnHintSubtopic && !subtopic2;
				animateInfoBox = hint.isInfoBoxText && subtopicOnly;
				animateFunFactBox = hint.isFunFact && subtopicOnly;
				animateVideoPopupIcon = hint.popup === 'video' && subtopicOnly && !showVideoPopup;
				animateRecipePopupIcon = hint.popup === 'recipe' && subtopicOnly && !showRecipePopup;
				animatesustainabilityPopupIcon = hint.popup === 'sustainability' && subtopicOnly 
					&& !showSustainabilityPopup;
				animateImagePopupIcon = hint.popup === 'image' && subtopicOnly && !showImagePopup;
				animateImage2PopupIcon = hint.popup === 'image2' && subtopicOnly && !showImage2Popup;
				animateCuttingInstructionsPopupIcon = hint.popup === 'cuttingInstructions' && subtopicOnly
					&& !showCuttingInstructionsPopup;
	
			}
		} else {
			let topicOnly = isOnHintTopic && !subtopic && !subtopic2;
			animateInfoBox = hint.isInfoBoxText && topicOnly;
			animateFunFactBox = hint.isFunFact && topicOnly;
			animateVideoPopupIcon = hint.popup === 'video' && topicOnly && !showVideoPopup;
			animateRecipePopupIcon = hint.popup === 'recipe' && topicOnly && !showRecipePopup;
			animatesustainabilityPopupIcon = hint.popup === 'sustainability' && topicOnly 
					&& !showSustainabilityPopup;
			animateImagePopupIcon = hint.popup === 'image' && topicOnly && !showImagePopup;
			animateImage2PopupIcon = hint.popup === 'image2' && topicOnly && !showImage2Popup;;
			animateCuttingInstructionsPopupIcon = hint.popup === 'cuttingInstructions' && topicOnly
				&& !showCuttingInstructionsPopup;
		}
	}
	
	return (
		<div className={'Explore'
			+ (topic ? ' Explore--topic Explore--' + topic.id : ' Explore--location Explore--' + location.id)}>
			<h2 className="Explore-gameTitle">{generalUiTexts.title}</h2>
			<div className="Explore-subtitle">{subtitle}</div>
			
			<div className={'Explore-content'
				+ (topic ? ' Explore-content--showSubtopics' : ' Explore-content--showTopics')
				+ (subtopic ? ' Explore-content--subtopic-' + subtopic.id : '')
				+ (subtopic2 ? ' Explore-content--subtopic2-' + subtopic2.id : '')
			}>
				<div className="InfoPanel">
					{/* InfoBox */}
					{infoBoxText && <div className={'InfoBox' + (animateInfoBox ? ' animate' : '')
						+ (funFact ? ' withFunFact' : ' maxHeight')}>
						<div ref={infoBoxRef} className={'InfoBox-body' + (!showPopupIcons ? ' maxHeight' : '') }>
							<div className="InfoBox-text">{renderMarkdown(infoBoxText)}</div>
						</div>
						<div className="InfoBox-assets">
							<div>
								{popupVideo
								&& <ReactSVG src={require('../../assets/images/icon-video.svg').default}
									className={'InfoBox-videoIcon'
									+ (animateVideoPopupIcon ? ' animate' : '')
									+ (showVideoPopup ? ' selected' : '')}
									onClick={() => {togglePopup('video', !showVideoPopup);}}/>}
								{popupCuttingInstructions
								&& <ReactSVG src={require('../../assets/images/icon-cuttingInstructions.svg').default}
									className={'InfoBox-cuttingInstructionsIcon'
									+ (animateCuttingInstructionsPopupIcon ? ' animate' : '')
									+ (showCuttingInstructionsPopup ? ' selected' : '')}
									onClick={() => {togglePopup('cuttingInstructions', !showCuttingInstructionsPopup);}}
								/>}
								{popupRecipe
								&& <ReactSVG src={require('../../assets/images/icon-recipe.svg').default}
									className={'InfoBox-recipeIcon'
									+ (animateRecipePopupIcon ? ' animate' : '')
									+ (showRecipePopup ? ' selected' : '')}
									onClick={() => {togglePopup('recipe', !showRecipePopup);}}/>}
								{popupSustainability
								&& <ReactSVG src={require('../../assets/images/icon-sustainability.svg').default}
									className={'InfoBox-sustainabilityIcon'
									+ (animatesustainabilityPopupIcon ? ' animate' : '')
									+ (showSustainabilityPopup ? ' selected' : '')}
									onClick={() => {togglePopup('sustainability', !showSustainabilityPopup);}}/>}
								{popupImage
								&& <ReactSVG src={require('../../assets/images/icon-image.svg').default}
									className={'InfoBox-imageIcon'
									+ (animateImagePopupIcon ? ' animate' : '')
									+ (showImagePopup ? ' selected' : '')}
									onClick={() => {togglePopup('image', !showImagePopup);}}/>}
								{popupImage2
								&& <ReactSVG src={require('../../assets/images/icon-image.svg').default}
									className={'InfoBox-imageIcon'
									+ (animateImage2PopupIcon ? ' animate' : '')
									+ (showImage2Popup ? ' selected' : '')}
									onClick={() => {togglePopup('image2', !showImage2Popup);}}/>}
							</div>
						</div>
					</div>}

					{/* Fun Fact */}
					{funFact && <div className={'FunFactBox' + (animateFunFactBox ? ' animate' : '')}>
						<div className="FunFactBox-title">{generalUiTexts.didYouKnow}</div>
						<div className="FunFactBox-text">{funFact}</div>
					</div>}
				</div>

				{/* Topics */}
				<div className="Explore-topics">
					{location.topics.map((topicData, i) => {
						// Animate topic if no topic or the wrong one were selected
						let animate = hint && hint.topic === topicData.id
							&& (!topic || (topic && topic.id !== hint.topic));
						return <div key={i}
							className={'Explore-topic Explore-topic--' + topicData.id + (animate ? ' animate' : '')}
							onClick={() => {selectTopic(topicData);}}>
							<img src={require('assets/images/' + topicData.image).default} alt={topicData.image} />
							<div><div>{topicData.name}</div></div>
						</div>;
					})}
				</div>
		
				{/* Subtopics */}
				<div className="Explore-subtopics">
					{topic && <React.Fragment>
						{topic.subtopics.map((subtopicData, i) => {
							let selected = subtopic && subtopicData.id === subtopic.id;
							// Animate subtopic if the correct topic is chosen and subtopic not selected
							let animate = ((!selected && isOnHintTopic && hint.subtopic === subtopicData.id)
											|| (subtopic2 && hint && !hint.subtopic2
												&& hint.subtopic === subtopicData.id))
												&& (hint.location === location.id && hint.topic === topic.id);

							return <div className="Explore-subtopicWrap" key={i}>
								<div
									className={'Explore-subtopic' +
									(selected ? ' selected' : '') + (animate ? ' animate' : '')}
									onClick={() => {selectSubtopic(subtopicData);}}
								>
									{subtopicData.name}
								</div>
								{/* Subtopics 2 */}
								{subtopicData.subtopics2 && subtopicData.subtopics2.length > 0 && selected &&
								subtopicData.subtopics2.map((subtopicData2, i) => {
									let selected = subtopic2 && subtopicData2.id === subtopic2.id;
									// Animate subtopic2 if the correct subtopic is chosen and subtopic2 not selected
									let animate = !selected && isOnHintSubtopic && hint.subtopic2 === subtopicData2.id;
									return <div key={i}
										className={'Explore-subtopic2' +
										(selected ? ' selected' : '') + (animate ? ' animate' : '')}
										onClick={() => {selectSubtopic2(subtopicData2);}}
									>
										{subtopicData2.name}
									</div>;
								})}
							</div>;
							
						})}
						
						{pigCuts.findIndex((cut) => {return cut.id === topic.id;}) < 0
							&& topic.image && <img className={'Explore-topicImg Explore-topicImg--' + topic.id}
							src={require('assets/images/' + topic.image).default} alt={topic.image}/>}
					</React.Fragment>}
				</div>
				{/* Pig component */}
				<Pig
					location={location}
					topic={topic}
					selectTopic={selectTopic}
					animatePigCut={pigCuts.findIndex((cut) => {return hint && cut.id === hint.topic;}) > -1}
					animatePig={pigCuts.findIndex((cut) => {return hint && cut.id === hint.topic;}) > -1
							&& topic && topic.id !== hint.topic}
					animatePigOnce={hint && hint.topic === 'pig'}
					hint={hint}
					freezeScreen={freezeScreen}
				/>
			</div>

				
			{/* Bottom panel: locations */}
			<div className="Explore-locations">
				{exploreData.map((locationData, i) => {
					
					let selected = locationData.id === location.id;
					
					// Animate location if it's not selected
					let animate = hint && hint.location === locationData.id
						&& (!selected
							// Or selected, special case for challenge 10 in theme 2
							|| (selected
								&& hint.topic === 'pig'
								&& topic && pigCuts.findIndex((cut) => {return cut.id === topic.id;}) === -1)
							// Or selected, but is wrong topic and not a pig topic 
							// (and except in 'farm') topic can see eachother
							|| (selected
								&& hint.topic !== 'pig'
								&& topic && topic.id !== hint.topic 
								&& (hint.location === 'butchery' 
								|| hint.location === 'education' || hint.location === 'consumer')
								&& (pigCuts.findIndex((cut) => {return cut.id === topic.id;}) === -1
									|| pigCuts.findIndex((cut) => {return cut.id === hint.topic;}) === -1))
							// Or selected, but hint is in intro info, fun fact or popup, so need to go back to overview
							|| (selected
								&& !hint.subtopic && subtopic && (hint.isInfoBoxText || hint.isFunFact || hint.popup))
						);
					return <div key={i}
						className={'Explore-location' + (selected ? ' selected' : '') + (animate ? ' animate' : '')}
						onClick={() => {selectLocation(locationData);}}>
						<img src={require('assets/images/' + locationData.image).default} alt={locationData.image} />
						<div>{locationData.name}</div>
					</div>;
				})}
			</div>

			{/* Popups */}
			<Popup
				showPopup={showRecipePopup}
				frameText={true}
				type="recipe"
				title={null}
				text={popupRecipe}
				image={null}
				video={null}
				onClose={() => {togglePopup('recipe', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'recipe'}
				popupRef={recipePopupRef}
			/>
			<Popup
				showPopup={showVideoPopup}
				frameText={true}
				type="video"
				title={null}
				text={null}
				image={null}
				video={popupVideo}
				onClose={() => {togglePopup('video', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'video'}
			/>
			<Popup
				showPopup={showImagePopup}
				frameText={true}
				type="image"
				title={null}
				text={null}
				image={popupImage}
				video={null}
				onClose={() => {togglePopup('image', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'image'}
			/>
			<Popup
				showPopup={showImage2Popup}
				frameText={true}
				type="image"
				title={null}
				text={null}
				image={popupImage2}
				video={null}
				onClose={() => {togglePopup('image2', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'image2'}
			/>
			<Popup
				showPopup={showCuttingInstructionsPopup}
				frameText={true}
				type="cuttingInstructions"
				title={null}
				text={popupCuttingInstructions}
				image={null}
				video={null}
				onClose={() => {togglePopup('cuttingInstructions', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'cuttingInstructions'}
			/>
			<Popup
				showPopup={showSustainabilityPopup}
				frameText={true}
				type="sustainability"
				title={null}
				text={popupSustainability}
				image={null}
				video={null}
				onClose={() => {togglePopup('sustainability', false);}}
				animate={hint && subtopic && subtopic.id === hint.subtopic && hint.popup === 'sustainability'}
			/>
		</div>
	);
};

Explore.propTypes = {
	location: PropTypes.object.isRequired,
	topic: PropTypes.object,
	subtopic: PropTypes.object,
	subtopic2: PropTypes.object,
	selectLocation: PropTypes.func.isRequired,
	selectTopic: PropTypes.func.isRequired,
	selectSubtopic: PropTypes.func.isRequired,
	selectSubtopic2: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
	popup: PropTypes.object.isRequired,
	hint: PropTypes.object,
	freezeScreen: PropTypes.bool.isRequired,
	infoBoxRef: PropTypes.object.isRequired,
	recipePopupRef: PropTypes.object.isRequired
};

export default Explore;
