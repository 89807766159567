import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Loading from 'components/loading/loading';
import ThemeOverviewController from 'components/themes-overview/theme-overview-controller';
import ExploreController from 'components/explore/explore-controller';
import Page from 'components/page/page';
import ImageLoader from 'components/image-loader/image-loader';
import config from 'config/app.config';
import imagesData from 'data/images-data';
import appConfig from 'config/app.config';

class StudentController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			game: null,
			student: null,
			studentIsPlaying: false,
			themeStreakUnlocked: false,
			showGameCompletedPopup: false,
			themeData: null,
			challengesData: [],
			themeChallengesData: [],
			hint: null,
			hintVisible: false,
			isPreloadingImages: true,
			preloadImages: true
		};

		this.unsubscribeGame = null;
		this.unsubscribeStudent = null;
	}


	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to game and student */
		let studentId = this.props.userId;
		let gameId = studentId.substr(0, studentId.indexOf('-'));
		Promise.all([
			this.subscribeToGame(gameId),
			this.subscribeToStudent(studentId)
		]).then(() => {
			/* Update state */
			this.setState({isLoading: false});
		});

	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / student */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeStudent !== null) this.unsubscribeStudent();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	}	

	/**
	 * Subscribe to game
	 * @param {string} gameId
	 */
	subscribeToGame = (gameId) => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get updated game */
					let data = doc.data();
					data.id = doc.id;
					let prevGameData = this.state.game;
					this.setState({ game: data }, () => {
						this.checkGameUpdates(prevGameData);
						resolve({ status: 'ok' });
					});
				} else {
					/* No game, probably deleted: log out student */
					this.props.handleLogout();
				}
			},
			(error) => {
				/* Error: game not found */
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Subscribe to own student
	 * @param {string} studentId
	 */
	subscribeToStudent = (studentId) => {
		if (this.unsubscribeStudent !== null) this.unsubscribeStudent();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeStudent = db.collection(appConfig.studentsDbName).doc(studentId).onSnapshot((doc) => {
				if (doc.exists) {
					let data = doc.data();
					data.id = doc.id;
					this.setState({ student: data }, () => {
						resolve({ status: 'ok' });
					});
				} else {
					this.props.handleLogout();	
				}
			},
			(error) => {
				console.error('could not get student: ', error);
				this.props.handleLogout();
			}
			);
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {

	};

	/**
	 * Update student data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updatePlayerData = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update student */
		let studentId = this.state.student.id;
		return new Promise((resolve)=>{
			if (studentId) {
				const db = firebase.firestore();
				db.collection(appConfig.studentsDbName).doc(studentId).update(updates).then(() => {
					resolve({status: 'ok'});
				}, (error) => {
					resolve({status: 'error', error: error});
				});
			} else {
				resolve({status: 'error', error: 'no-player-id'});
			}
		});
	};

	/* Reset student data (testing purposes) */
	resetData = () => {
		this.updatePlayerData({challenges: [], points: 0, completed: null});
	}

	/* Show an animation to area where student needs to navigate to (hint button clicked) */
	showHint = () => {
		this.setState({hintVisible: true});
	}

	/* Set hint data from question */
	setHint = (hint) => {
		this.setState({
			hintVisible: false,
			hint: hint
		});
	}

	/* Check if all images have been preloaded */
	handlePreloadImage = (preloadingFinished) => {
		if (preloadingFinished) {
			this.setState({isPreloading: false, preloadImages: false});
		}
	}

	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading || this.state.isPreloading) {
			return (
				<React.Fragment>
					<Loading
						loadErrMsg={this.state.loadErrMsg}
						handleLogout={this.props.handleLogout}
					/>
					{this.state.preloadImages
						&& <ImageLoader images={imagesData} handlePreloadImage={this.handlePreloadImage} />};
				</React.Fragment>
			);
		};

		return <Page handleLogout={this.props.handleLogout} isTeacher={false}>
			<ExploreController hint={this.state.hintVisible ? this.state.hint : null}/>
			<ThemeOverviewController
				playerData={this.state.student}
				updatePlayerData={this.updatePlayerData}
				game={this.state.game}
				setHint={this.setHint}
				showHint={this.showHint}
			/>
			{config.env !== 'production' && <div className="ResetBtn" onClick={() => {this.resetData();}}>Reset</div>}
		</Page>;
	}
}

StudentController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default StudentController;
