import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Loading from 'components/loading/loading';
import SettingsController from 'components/settings/settings-controller';
import Page from 'components/page/page';
import appConfig from 'config/app.config';

class TeacherController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadErrMsg: null,
			page: 'game-setup',
			userData: null,
			game: null,
			students: [],
			gameId: null,
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGames = null;
		this.unsubscribeStudents = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get teacher data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Subscribe to games */
				this.subscribeToGames().then(() => {
					this.setState({isLoading: false});
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from games */
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
	}

	/**
	 * Subscribe to user (teacher) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.usersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {
						resolve({ status: 'success' });
					});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			}
			);
		});
	}

	/**
	 * Called by the teacher to subscribe to all their games.
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query = db.collection(appConfig.gamesDbName).where('teacherId', '==', this.props.userId);
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let data = null;
				if (querySnapshot.docs.length > 0) {
					data = querySnapshot.docs[0].data();
					data.id = querySnapshot.docs[0].id;
				}
				this.setState({game: data}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get game: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Subscribe to the students of a game
	 * @param {string} gameId
	 */
	subscribeToStudents = (gameId) => {
		if (this.unsubscribeStudents !== null) this.unsubscribeStudents();
		
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeStudents = db.collection(appConfig.studentsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let students = [];
					querySnapshot.forEach((doc) => {students.push({id: doc.id, ...doc.data()});});
					this.setState({ students: students }, () => {
						resolve();
					});
				},
				(error) => {
					console.error('could not get students: ', error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Update user (teacher) data
	 * @param {object} updates
	 * @returns
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection(appConfig.usersDbName).doc(userId);
		return userRef.update(updates);
	}

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.gameId;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading) {
			return (
				<Loading
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout}
				/>
			);
		};

		return <Page handleLogout={this.props.handleLogout} isTeacher={true}>
			<SettingsController game={this.state.game} userId={this.props.userId}/>
		</Page>;
	}
}

TeacherController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default TeacherController;
