import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {orderPoints} from 'data/points-data';
import Order from './order';

class OrderController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPaused: false,
			animation: null,
			orderData: null,
			selectedItemId: null
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load challenge */
		this.loadChallenge();
	}

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.challengeData.id !== prevProps.challengeData.id) {
			this.setState({isLoading: true, orderData: null}, () => {this.loadChallenge();});
		}
	}

	/**
	 * Load order & sync with player
	 */
	loadChallenge = () => {
		/* Get order data */
		let challengeData = JSON.parse(JSON.stringify(this.props.challengeData));
		challengeData.completed = false;
		challengeData.errors = 0;

		/* Shuffle itmes */
		challengeData.items = shuffleArray(challengeData.items);
		
		/* Sync with player progress */
		if (
			this.props.playerData.hasOwnProperty('challenges') &&
				this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				challengeData.completed = playerChallengeData.completed;
			}
			challengeData.errors = playerChallengeData.errors;
			challengeData.boxes.forEach((box, i) => {
				if (Array.isArray(box.correctItemId)) {
					if (box.correctItemId.includes(playerChallengeData.orderedItems[i])) {
						box.placedItemId = playerChallengeData.orderedItems[i];
					}
				} else {
					if (playerChallengeData.orderedItems.indexOf(box.correctItemId) >= 0) {
						box.placedItemId = box.correctItemId;
					}
				}
			});
		}

		/* Update state */
		this.setState({isLoading: false, isPaused: challengeData.completed, orderData: challengeData}, () => {
			/* Adjust body height */
			// let headerElement = document.getElementById('OrderHeader');
			// let bodyElement = document.getElementById('OrderBody');
			// if (headerElement && bodyElement) {
			// 	let fontSize = 16 * ((headerElement.clientWidth / 360));
			// 	let headerHeight = headerElement.clientHeight + (0.5 * fontSize);
			// 	bodyElement.style.paddingTop = headerHeight + 'px';
			// }
		});
	}

	/**
	 * Select item
	 * @param {number} itemId
	 */
	handleSelectItem = (itemId) => {
		if (this.state.isPaused) return;

		this.setState({isPaused: true}, () => {
			let selectedItemId = (this.state.selectedItemId === itemId ? null : itemId);
			this.setState({isPaused: false, selectedItemId});
		});
	}	

	/**
	 * Select box
	 * @param {number} boxIndex
	 */
	handleSelectBox = (boxIndex) => {
		if (this.state.isPaused || this.state.selectedItemId === null) return;
		if (this.state.orderData.boxes[boxIndex].placedItemId !== null) return;

		this.setState({isPaused: true}, () => {
			let challengeData = JSON.parse(JSON.stringify(this.state.orderData));
			let isCorrect = Array.isArray(challengeData.boxes[boxIndex].correctItemId)
				? (challengeData.boxes[boxIndex].correctItemId.includes(this.state.selectedItemId))
				: (challengeData.boxes[boxIndex].correctItemId === this.state.selectedItemId);

			/* Update game data */
			if (!isCorrect) {
				/* Wrong placement */
				challengeData.errors = challengeData.errors + 1;
				this.setState({animation: 'wrong', orderData: challengeData});
			} else {
				/* Correct placement */
				challengeData.boxes[boxIndex].placedItemId = this.state.selectedItemId;
				this.setState({animation: 'correct', orderData: challengeData});
			}

			/* Player data - progress and errors */
			let playerChallenges = [];
			let orderedItems = new Array(this.state.orderData.boxes.length).fill(null);
			challengeData.boxes.forEach((box, i) => {
				if (box.placedItemId) orderedItems[i] = box.placedItemId;
			});
			if (this.props.playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
			}
			let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
				return challenge.id === challengeData.id;
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: challengeData.id, completed: false, errors: challengeData.errors, orderedItems: orderedItems
				});
			} else {
				playerChallenges[playerChallengeIndex].errors = challengeData.errors;
				playerChallenges[playerChallengeIndex].orderedItems = orderedItems;
			}

			/* Player data - points */
			let playerPoints = this.props.playerData.hasOwnProperty('points') 
				? JSON.parse(JSON.stringify(this.props.playerData.points))
				: 0;

			/* Update player data */
			this.props.updatePlayerData({
				challenges: playerChallenges,
				points: playerPoints
			}).then((response) => {
				if (response.status === 'ok') {
					this.timeout = setTimeout(() => {
						/* Check if all items have been ordered */
						let challengeIsComplete = (!challengeData.boxes.some((box) => {
							return box.placedItemId === null;
						}));

						if (challengeIsComplete) {
							/* Update game state, handle challenge completed */
							this.setState({selectedItemId: null, animation: null});
							this.orderingCompleted();
						} else {
							/* Update game state */
							this.setState({isPaused: false, selectedItemId: null, animation: null});
						}
					}, 500);
				} else {
					console.log('error');
				}
			});
		});
	}

	/**
	 * Order completed
	 */
	orderingCompleted = () => {
		let challengeData = JSON.parse(JSON.stringify(this.state.orderData));
		challengeData.completed = true;

		let points = orderPoints.minPoints;
		let pointIndex = orderPoints.pointLimits.findIndex((limit) => {return challengeData.errors <= limit;});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		this.props.completeChallenge(challengeData.id, points).then(() => {
			this.setState({orderData: challengeData});
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		if (!this.state.isLoading && this.state.orderData) {
			return (
				<Order
					isPaused={this.state.isPaused}
					selectedItemId={this.state.selectedItemId}
					animation={this.state.animation}
					orderData={this.state.orderData}
					handleSelectItem={this.handleSelectItem}
					handleSelectBox={this.handleSelectBox}
				/>
			);
		}
		return null;
	}
}

OrderController.propTypes = {
	challengeData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	completeChallenge: PropTypes.func.isRequired
};

export default OrderController;