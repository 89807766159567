const pigCuts = [
	{
		id: 'loin',
		name: 'Kam',
		image: 'pig/loin.svg',
		imageZoomed: 'pig/loin-zoomed.svg',
		intro: `Kammen på grisen er det lange stykke over griseryggen. 
		Den kan skæres i mindre udskæringer. 
		
		En af danskernes favoritter på julebordet udskæres blandt andet fra kammen.`,
		introImage: null,
		subtopics: [
			{
				id: 'roast-pork',
				name: 'Flæskesteg',
				subcutImage: null,
				text: 'Her findes den klassiske flæskesteg - en af favoritterne fra julebordet. Stykket er magert og kræver nænsom tilberedning.',
				video: require('assets/videos/Vidste_du_Flæskesteg.mp4').default,
				recipe: require('data/recipes/kam/flæskesteg.md').default,
				image: 'flæskesteg-hel.jpg',
				cuttingInstructions: `Flæskesteg skæres af hele grisekammen uden mørbradklods. 

				Flæskestegen skæres hovedsageligt, så den kun består af kammusklen og uden ben.  
				
				Sværen ridses med ½ - 1 cm's mellemrum ned til kødet, undgå at ridse i selve kødet. 
				
				Hele kammen deles i 3 - 4 stykker. 
				
				Stegene har forskellige højder og dermed forskellige stegetider.`,
				sustainability: `Undgå madspild: Skær rester af flæskestegen i tynde skriver, og brug dem som pålæg på rugbrød og i burgere.`,
			},
			{
				id: 'ribs',
				name: 'Sauté-skiver',
				subcutImage: null,
				text: 'Sauté-skiver er skåret meget tynde og derfor meget, meget magre. De skal tilberedes lynhurtigt på en meget varm pande.',
				video: null,
				recipe: require('data/recipes/kam/saute-skiver.md').default,
				image: 'saute-skiver-gris.jpg',
				funFact: 'Man kan gourmetsalte magert kød for at det bliver mere mørt og saftigt.',
				cuttingInstructions: `Sauté-skiverne skæres af Filet Royal (Klik på 'Filet Royal' for at se dens skærevejledning).

				Skær bagefter meget tynde skiver af Filet Royalen.`
			},
			{
				id: 'fillet-royal',
				name: 'Filet Royal',
				subcutImage: null,
				text: `Filet Royal skæres af det midterste stykke på kammen. 
				Det er det mest magre stykke kød på grisen - derfor kan mange godt lide af marinere det i barbecuesauce.`,
				video: null,
				recipe: require('data/recipes/kam/fillet-royal.md').default,
				image: 'fillet-royal.jpg',
				cuttingInstructions: `Filet royal skæres af kam uden mørbradklods, uden støttemuskel og helt afpudset for ben, svær, fedt, sener og hinder. 

				Herefter fraskæres 7 cm fra mørbradenden og op til mellem 8. og 9. ribben ved nakkeenden. 
				
				Hele stykket har samme tykkelse.`
			},
			{
				id: 'svinekam',
				name: 'Svinekam u. svær',
				subcutImage: null,
				text: 'Svinekam uden svær er stort set det samme som flæskestegen - bare uden de sprøde svær!',
				video: null,
				funFact: "'Engelsk' bacon laves på svinekammen uden svær. Det har mindre fedt, men bliver stadig sprødt.",
				recipe: require('data/recipes/kam/svinekam.md').default,
				image: 'svinekam-uden-svær.jpg',
				cuttingInstructions: `Svinekam, uden svær, skæres af hele kammen, hvor mørbradklodsen ikke skæres med.  

				Ben, svær samt kappen på kammens overside fjernes, således at kammen bliver ens i tykkelsen. 
				
				Fedtet trimmes ned til 3 mm.`
			},
		]
	},
	{
		id: 'skank',
		name: 'Skank',
		image: 'pig/skank.svg',
		imageZoomed: 'pig/skank-zoomed.svg',
		intro: 'Forskanken er et stykke kød på ben, der er meget nemt at håndtere. <br/><br/>'+
		'Udskæringen indeholder meget bindevæv og fedt, og det gør kødet saftigt. <br/><br/>'+
		
		'Skank er velegnet til frikasseer, gryderetter og i supper, men den kan også steges eller grilles.',
		introImage: 'skank-gris.jpg',
		introCuttingInstructions: 'Forskanken saves af boven, lige over albueleddet, i et lige snit. <br/><br/>'+

		'Rørbenet skæres ud ved at løsne klumpen helt ned til yderlåret, så tæt ved benet som muligt. <br/><br/>'+
		
		'Kødet skæres fra knæskallen, så den er fri. <br/><br/>'+
		
		'Rørbenet løsnes fra musklen ved at skære så tæt på benet som muligt.',
		introRecipe: require('data/recipes/skank/skank2.md').default,
		subtopics: [],
		funFact: `Grisetæer er en populær snack blandt asiatiske kvinder, da de indeholder meget kollagen, der giver smuk hud.`,
	},
	{
		id: 'bryst',
		name: 'Bryst',
		image: 'pig/bryst.svg',
		imageZoomed: 'pig/bryst-zoomed.svg',
		intro: `Grisens fedtholdige bryststykke leverer nogle af vores mest populære og folkekære kødprodukter, nemlig bacon, stegeflæsk, rullepølse og ribbenstegen. 

		Når grisebrystet udskæres, så inddeles det i et stegestykke, et kogestykke og et slag. 
		
		Stegestykke og kogestykke kan begge bruges til ribbensteg med ben. Ribbenstegen kan udskæres videre til stegeflæsk og brystflæsk. 
		
		Slaget bruges til at lave rullepølse.`,
		introImage: null,
		introCuttingInstructions: `Hele brystet deles midt igennem, så den ene halvdel består af stegestykke og slag og den anden af kogestykke og slag.`,
		introRecipe: null,
		funFact: 'På Sjælland bruger man over 330 millioner kr. på bacon om året.',
		subtopics: [
			{
				id: 'stegeflæsk',
				name: 'Stegeflæsk',
				text: `For at få det sprødeste og mest saftige stegte flæsk, skal man bruge skiver skåret af stegestykket på brystet.`,
				video: null,
				funFact: 'Stegt flæsk blev kåret som Danmarks nationalret i 2014.',
				recipe: require('data/recipes/bryst/stegeflæsk.md').default,
				image: 'stegeflæsk.jpg',
				cuttingInstructions: `Stegestykket på brystet skæres i lige tykke skiver, 0,7- 1,4 cm tykke. 

				De fleste er på ca. 1 cm.`
			},
			{
				id: 'bacon',
				name: 'Bacon',
				text: `Bacon er en af de madvarer, de fleste mennesker forbinder med Danmark. Og faktisk har mennesket udskåret og spist bacon siden 7000 år før Kristus.

				Den danske bacon fremstilles af det fedtholdige bryststykke, men der findes også 'engelsk bacon'. Det skæres fra kammen og er mere magert.`,
				video: null,
				recipe: null,
				image:'bryst_hel_uden_ben_overside.jpg',
				cuttingInstructions: `Bacon fremstilles af et helt bryststykke uden ben, som saltes og røges, og til sidst skæres i tynde skriver.`,
				funFact: 'Verdensrekorden i baconspisning er 182 skiver på 5 minutter.'
			},
			{
				id: 'svinebryst',
				name: 'Svinebryst',
				text: `Brystet indeholder meget bindevæv og fedt, og er meget nemt at få god smag ud af. Det er taknemmeligt at tilberede, hvis bare man har tid nok.`,
				video: null,
				recipe: require('data/recipes/bryst/svinebryst.md').default,
				image: 'svinebryst.jpg',
				cuttingInstructions: `Bryst af gris uden ben skæres af kogestykke, stegestykke og slag. 

				Ben og brusk fjernes ved at skære dem af eller ved at rykke dem af.  Det er mest almindeligt at skære dem af, så er undersiden af kødstykket jævn. Hvis det bliver rykket af, bliver undersiden ”flosset”. 
				
				Hele brystet deles midt igennem, så den ene halvdel består af stegestykke og slag og den anden af kogestykke og slag.`,
				funFact: null
			},
			{
				id: 'Ribbensteg',
				name: 'Ribbensteg',
				text: `Mange mennesker foretrækker faktisk ribbenstegen frem for den klassiske flæskesteg. Men hvad er egentlig forskellen på de 2 stege?

				Jo, den klassiske flæskesteg kommer fra grisens kam (ryg) og er fedtfattig. Man får meget kød ud af stegen, men hvis man ikke tilbereder den korrekt, bliver kødet hurtigt tørt.
				
				Ribbenstegen kommer fra grisens bryst og kan være med eller uden ben. Ribbenstegen er mere fedtholdig, men lettere at tilberede med et godt resultat.`,
				video: null,
				recipe: require('data/recipes/bryst/ribbensteg.md').default, 
				image: 'ribbenssteg_af_stegestykke_med_ben.jpg', 
				cuttingInstructions: `Hele brystet deles midt igennem, så den ene halvdel består af stegestykke og slag og den anden af kogestykke og slag.

				Brystet deles hver i tre, så der bliver seks ribbensstege.`,
				funFact: null,
				sustainability: `Undgå madspild:
				Rester skæres i tynde skiver og bruges som pålæg på rugbrød eller i sandwich, pitabrød, tortillas og lignende.	`
			},
			{
				id: 'Slag',
				name: 'Slag',
				text: `Slaget sidder på den bagerste del af brystet, og kan både være med eller uden svær. 

				Slag uden svær bruges til at lave rullepølse og rullesteg. Slag med svær på kan bruges til den italienske rullesteg - porchetta - som steges knasende sprød på indirekte grill.`,
				video: null,
				recipe: null,
				image: 'slag.jpg',
				image2: 'rullesteg_af_slag.jpg',
				cuttingInstructions: `Slag afpudses for fedt, svær og ben.

				Fjern også gerne den store sene.
				
				Kødet skæres til en firkant.`,
				funFact: null,
				sustainability: `Undgå madspild:
				Rester skæres i tynde skiver og bruges som pålæg på rugbrød eller i sandwich, pitabrød, tortillas og lignende.`
			},
		]
	},
	{
		id: 'indmad',
		name: 'Indmad',
		image: 'pig/indmad.svg',
		imageZoomed: 'pig/indmad-zoomed.svg',
		intro: `Hvad er indmad? 
		Indmad er de spiselige dele af indvoldene i en gris dvs. lever, nyrer, tarme, mavesæk og hjerte.  Faktisk regnes både testikler, tunge, hjerne og blod også for at være indmad. 
		
		I dag er der få danskere, der spiser indmad jævnligt - selvfølgelig med undtagelse af leverpostejen. 
		
		De ældre generationer stoppede med at tilberede indmad, fordi det røg ud supermarkederne i takt med at andet kød blev billigere.  Derfor er de yngre generationer ikke opdraget til at sætte pris på indmad. Og det er synd, for indmad er både sund og billig mad. 
		
		De meste anvendte typer indmad i Danmark er lever, hjerte og selvfølgelig tarme.`,
		introImage: null,
		introCuttingInstructions: `Indmad er let fordærvelig og skal derfor fjernes fra slagtekroppen hurtigt efter slagtning og lægges på køl.`,
		introRecipe: null,
		funFact: 'I Region Nordjylland spiser man omtrent dobbelt så mange pølser som i Region Hovedstaden.',
		subtopics: [
			{
				id: 'lever',
				name: 'Lever',
				text: `Lever kan bruges til klassiske retter som leverpostej, men kan også bruges, hvor det er skåret i tynde skiver og bliver lynstegt på en pande.`,
				video: null,
				recipe: require('data/recipes/indmad/lever.md').default,
				image: 'lever-gris.jpg',
				cuttingInstructions: 'På hel lever skal blodårerne fjernes før brug.',
				funFact: 'Sønderjyder spiser 2,8 kg leverpostej om året (50% mere end i Region Hovedstaden).'
			},
			{
				id: 'hjerte',
				name: 'Hjerte',
				text: `Hjertet er ren og meget aktiv muskel man sagtens kan tilberede og spise. Det egner sig især godt til en simreret, hvor den giver god smag til sovsen.`,
				video: null,
				recipe: require('data/recipes/indmad/hjerte.md').default,
				image: 'hjerte-gris.jpg',
				cuttingInstructions: null,
				funFact: null
			},
			{
				id: 'tarme',
				name: 'Tarme',
				text: `Grisens mavesæk og tarmsæt er en værdifuld råvare. Når grisen er slagtet, tages mavesækken og tarmsættet ud og begge dele renses grundigt. Bagefter lægges delene i saltvand, som gør at de kan holde sig.  

				Mavesækken eksporteres typisk til asiatiske lande, hvor den er en helt normal råvare i madlavningen.
				
				Grisens tarme bruges til pølseproduktion. De danske grisetarme er anset for at være et produkt med meget høj kvalitet og sælges verden over. Tarme er en stor del af den danske kødproduktion.
				
				Grisetarme kaldes også ”naturtarme”. Der findes nemlig også ”kunst-tarme” (kunstige tarme), som er lavet af papir-fiber  eller plastik.`,
				video: require('assets/videos/Stopning af pølser film.mp4').default,
				recipe: require('data/recipes/indmad/tarme.md').default,
				image: 'Tarmset-inkl-mave.jpeg',
				cuttingInstructions: null,
				funFact: `I Danmark produceres der hvert år medistertarme der kan nå rundt om jorden 8 gange - 334.000 km tarme.`,
			},
		]
	},
	{
		id: 'skinke',
		name: 'Skinke',
		image: 'pig/skinke.svg',
		imageZoomed: 'pig/skinke-zoomed.svg',
		intro: `Hele grisens store bagende kaldes for skinken.

		Det er fra skinken man får udskæringer som skinkesteg, culottesteg, mignon og schnitzel, og det er kødet fra bagendens yderlår der bruges til at lave skinkestrimler og hakket svinekød. 
		
		Kødet fra grisens bagende er magert, og man skal passe på ikke at tilberede det for længe. Så bliver det nemlig tørt at spise.`,
		introImage: null,
		introCuttingInstructions: null,
		introRecipe: null,
		funFact: null,
		subtopics: [
			{
				id: 'minutstrimler',
				name: 'Skinkestrimler',
				subcutImage: null,
				text: `Skinkestrimler, skæres oftest af klump og yderlår fra skinken. 

				Strimlerne egner sig især godt til asiatiske retter og koreansk barbecue.`,
				video: null,
				recipe: require('data/recipes/skinke/minutstrimler.md').default, 
				image: 'minutstrimler.jpg',
				cuttingInstructions: `Skinkestrimler er hovedsageligt skåret af yderlår og klump. 

				Strimler skæres hovedsageligt i størrelsen 1 x 1 x ca. 5 cm.`,
				funFact: null
			},
			{
				id: 'mignon',
				name: 'Mignon',
				subcutImage: null,
				text: `Mignon er navnet på en udskæring, der kommer fra yderlåret på grisens bagende (skinken). 

				Et lidt lettere kaldenavn for udskæringen er "lårtungen".
				
				Den bedste spiseoplevelse fås, når mignon er svagt rosa i midten ved servering. Mignon egner sig til alle tilberedningsformer, så det er bare at gå i gang med at eksperimentere med de mange muligheder.`,
				video: null,
				recipe: require('data/recipes/skinke/mignon.md').default,
				image: 'mignon.jpg',
				cuttingInstructions: `Mignon/lårtunge skæres fra i hinden til yderlåret. 

				Lårtungen skæres igennem ved brusket fra nøglebenet og afpudses for sener og fedt.`,
				funFact: `"Mignon" er fransk og betyder "sød" eller "lækker." Det udtales "mee-ny-on".`,
			},
			{
				id: 'Hakket svinekød',

				name: 'Hakket svinekød',
				text: `Kødhakkeren blev opfundet i 1850. Før det, var det kun det fornemme herskab, der spiste hakket kød, for det tog lang tid at hakke kødet med en kniv. Da kødhakkeren kom til gik det stærkt med at få frikadeller, kåldolmere og andre farsretter på menuen.`,
				video: require('assets/videos/Vidste_du_Frikadeller_1.mp4').default,
				
				recipe: require('data/recipes/detail/hakkket-svinekød.md').default, 
				image: 'hakket_8_procent_fedt.jpg',
				funFact: null,
				cuttingInstructions: `Hakket kød laves af afskær og restkød fra grisen. Særligt på grisens bov, skank og skinke får man kødrester, som ikke indgår i andre udskæringer, og som kan kommes i kødhakkeren. Husk at tilsætte 7-8% fedt. Svinekød er i sig selv meget magert, så uden fedt i farsen kan det blive nogle tørre frikadeller.`,
				sustainability:`Stop madspild: 
				Hakket kød har en kort holdbarhed, så brug det straks eller frys det ned til senere brug.
				Forklaring: 
				Efter slagtning er de hele stykker kød sterile i midten. Det er derfor kun på overfladen af kødet, at bakterier kan vokse, og hvis man opbevarer kødet korrekt på køl, så spreder bakterierne sig kun langsomt. Men når man hakker kød, så ødelægges den sterile kerne, og bakterierne kan pludselig vokse og formere sig hurtigere.  Derfor - brug det hakkede kød hurtigt.`,
			},
		]
	},
	
	{
		id: 'tenderloin',
		name: 'Mørbrad',
		image: 'pig/tenderloin.svg',	
		imageZoomed: 'pig/tenderloin-zoomed.svg',
		introVideo: require('assets/videos/Vidste_du_Mørbrad.mp4').default, 
		intro: `Mørbrad er en muskel, der ligger gemt inde midt i grisen.  
		Mørbraden fungerer godt både som hel steg og udskåret i bøffer. Den kan både steges på panden eller grilles, og fungere med det meste tilbehør. 
		Mørbraden er nem at håndtere og tilberede, men man skal ikke stege den for længe for så bliver den tør.`,
		introImage: 'tenderloin.jpg',
		sustainability: `Undgå madspild:
		Skær de kolde mørbradbøffer i skiver eller strimler og bland dem i en salat eller brug dem som pålæg på brødet.`,
		introCuttingInstructions: `Mørbrad kan købes uafpudset med bimørbrad, letafpudset med og uden bimørbrad og helt afpudset uden sølvsene, fedt og hinder.

		Bimørbraden skæres evt. af. Men den kan sagtens være med på mørbraden. 
		
		Sølvsenen skal fjernes, da den er sej og ikke til at tygge.  
		
		Mørbraden kan pudses af for øvrige sener, fedt og hinder, men de kan sagtens spises.`,
		introRecipe: require('data/recipes/tenderloin/tenderloin.md').default,
		subtopics: [],
		funFact: `I dag betragtes mørbraden som en finere udskæring, men sådan har det ikke altid været. Klik på film-ikonet og hør hvorfor.`,
	},
	{
		id: 'neck',
		name: 'Nakke',
		image: 'pig/neck.svg',
		imageZoomed: 'pig/neck-zoomed.svg',
		intro: `Nakken er et dejligt stykke kød, der med sin fedtmarmorering kan holde sig smagsfuldt og saftigt ved længere tilberedning.

		Fra grisens nakke får man nakkestegen, som er med svær. Hvis man skærer sværen af nakkestegen, så har man til gengæld en nakkefilet. 
		
		Både nakkesteg og nakkefilet kan skæres ud i skiver og blive til lækre nakkekoteletter.`,
		introImage: null,
		introCuttingInstructions: `Ud af nakkestykket får man en nakkekam med fedtlag og svær, som kan bruges til at lave en skøn nakkesteg.

		Hvis man pudser svær og fedtlag af nakkekammen, så står man med en nakkefilet. 
		
		Nakkekoteletter skæres af nakkefilet.`,
		introRecipe: null,
		subtopics: [
			{
				id: 'Nakkefilet',
				name: 'Nakkefilet',
				subcutImage: null,
				text: `Fedtmarmoreringen i nakkefileten giver kødet smag og og gør det saftigt selv efter længere tilberedninger. 
				Nakkefilet er velegnet til at blive tilberedt både som en hel steg, men også udskåret til koteletter. Nakkefilet kan også anvendes til at lave den populære pulled pork.`,
				video: null,
				recipe: require('data/recipes/nakke/nakkefilet.md').default,
				image: 'nakkefilet.jpg',
				cuttingInstructions: `Nakkefilet skæres af nakkekam. Svær og fedt skæres af.  

				Nakkekoteletter skæres af nakkefilet i lige tykke skiver på ca. 1, 1½ eller 2 cm.`,
				funFact: null,
				sustainability: `Undgå madspild:
				Stegt nakkefilet kan skæres i skiver og varmes på grillen eller skæres i tern og bruges i biksemad.`,
			},
			{
				id: 'Nakkesteg',
				name: 'Nakkesteg',
				subcutImage: null,
				text: `Nakkestegen er med svær, og kan derfor anvendes som et alternativ til flæskesteg. Nakkestegens fedt gør, at man ikke skal være lige så nænsom med tilberedningen, som når man laver flæskesteg af fx. kam.`,
				video: null,
				recipe: require('data/recipes/nakke/flæskesteg.md').default,
				image: 'nakkesteg.jpg',
				cuttingInstructions: `Nakkestegen skæres af forenden. 

				Den er uden ben.
				
				Der fjernes en kile af spækket fra rygsiden, så spæktykkelsen bliver ens.
				
				Sværen ridses med ½ - 1 cm mellemrum og stegen snøres, da sværen ikke sidder fast i kødet, hvor bovbladet er fjernet.`,
				funFact: null,
				sustainability: `Undgå madspild:
				Resterne fra nakkestegen kan skæres i tynde skiver og bruges som pålæg på rugbrød eller i sandwich, pitabrød, tortillas og lignende. 
				Skær evt. sværen af, da den kan blive lidt blød af at ligge i køleskabet.`
			},
		]
	},
	{
		id: 'head',
		name: 'Grisehovede',
		image: 'pig/head.svg',
		imageZoomed: 'pig/head-zoomed.svg',
		intro: `Man skulle måske ikke tro det, men der gemmer sig masser af god mad i et grisehoved. 

		I Danmark er grisekæber meget populære i gryderetter, og den traditionelle julesylte laves med kød fra et kogt grisehoved. 
		
		I Asien bliver både hjerne, ører, tunge, tryne og det kød, der findes på grisehovedet spist. Hjernen opfattes endda som en af de største delikatesser, og det er der god grund til. Hjernen indeholder nemlig nogle særlige fedttyper, som vi mennesker har svært ved at få nok af i vores kost. 
		
		Vi har ikke opskrifter med grisehjerne med her, men hvis du en dag kommer en tur til Kina, så kan du gå ind på en hot-pot restaurant og bestille et sundt måltid mad med kogte danske grisehjerner.`,
		introImage: null,
		introCuttingInstructions: ``,
		introRecipe: null,
		funFact: 'I Kina er griseører en populær snack. Ørene koges i bouillon og skæres i tynde strimler.',
		subtopics: [
			{
				id: 'Grisekæber',
				name: 'Grisekæber',
				subcutImage: null,
				text: `Grisekæber er blevet virkelig populære i Danmark i det sidste årti, og det er ikke uden grund, for kæben er et dejligt stykke kød, som smager skønt. 
				Kæber har et højt indhold af protein og er fulde af bindevæv, som grisen har motioneret grundigt hver gang den har spist sin mad. Derfor skal der bruges lang tilberedning for at kødet blive mørt. 
				
				Kæberne egner sig godt til gryderetter, hvor de kan stå og simre i lang tid i en skøn sauce.`,
				video: null,
				recipe: require('data/recipes/head/grisekæber.md').default,
				image: 'kaebe.png', 
				cuttingInstructions: `Kæberne skæres af hovedet og afpudses for fedt og sener.`,
				funFact: `Bindevæv findes i hele kroppen og er det, der sørger for, at vores organer bliver på deres plads.`,
				sustainability: `Brug resterne: Skær kødresterne i strimler og bland dem i en salat, wokret, omelet, grøntsagssuppe eller brug dem i pitabrød, tortillas og lignende.`
			},
		],
		
	},
	{
		id: 'tail',
		name: 'Grisehale',
		image: 'pig/tail.svg',
		imageZoomed: 'pig/tail-zoomed.svg',
		intro: `Grisens hale består af et nøgleben og et halestykke.
		I gamle dage kogte man suppe på grisehalerne og gnavede kødet af hale og nøgleben, men i dag bliver grisehaler mest brugt som tygge-snack til hunde. 
		Grisehaler eksporteres også til Asien, hvor de ligesom ørerne er meget populære pga. det seje bindevæv, som særligt kineserene elsker at tygge på.`,
		introImage: 'Grisehale.jpg', 
		introCuttingInstructions: ``,
		subtopics: [],
		introRecipe: require('data/recipes/tail/tail.md').default,
		funFact: ``,
		sustainability: `Nogle grise bider hinanden i halen. 
		Det sker, fordi nogle små-grise har svært ved at slippe sutte-vanen, og det kan altså gå ud over naboen. 
		Det er lidt ligesom børn, der sutter på tommelfingeren, når man tager sutten fra dem. 
		Der er ikke altid problemer med halebid, men hvis først een gris begynder, så kan det udvikle sig i flokken. 
		Det er ikke rart at blive bidt i halen, og det kan også give betændelse, som kræver medicin. For at undgå dette, kuperer (opererer) nogle landmænd spidsen af halerne væk. 
		Grisene er selvfølgelig bedøvede, men det er alligevel ikke optimal dyrevelfærd at halekupere.  Derfor arbejder man på, at finde andre metoder til at undgå halebid. Man ved  allerede, at der er færre halebid, hvis grisene har legetøj i stalden, men der forskes i endnu hvordan man helt kan undgå halebid.`,
	},
	{
		id: 'bagskank',
		name: 'bagskank',
		image: 'pig/bagskank.svg',
		imageZoomed: 'pig/bagskank-zoomed.svg',
		intro: `Bagskanken på grisen er et stykke kød, der stadig er på benet og nemt at håndtere. På grund af meget bindevæv og fedt, kan kødet holde sig saftigt selv ved længere tilberedninger. 

		Skank er god til frikasseer og gryderetter, hvor det giver masser af smag.`,
		introImage: 'bagskank.jpg',
		introCuttingInstructions: `Bagskanken skæres af i knæleddet, i et skråt snit, gennem musklen. 

		Rørbenet skæres ud ved at løsne klumpen helt ned til yderlåret, så tæt ved benet som muligt. 
		
		Kødet skæres fra knæskallen, så den er fri. 
		
		Rørbenet løsnes fra musklen ved at skære så tæt på benet som muligt.`,
		introRecipe: require('data/recipes/skank/skank.md').default,
		subtopics: [],
		funFact: ``,
		sustainability: `Undgå madspild:
		Rester kan skæres i tern og bruges i biksemad.`
	},
	{
		id: 'bov',
		name: 'bov',
		image: 'pig/bov.svg',
		imageZoomed: 'pig/bov-zoomed.svg',
		intro: `Boven er den øverste del af forbenet på grisen. Her sidder muskler, der bliver brugt rigtig meget, og som indeholder meget bindevæv. 

		Boven anvendes mest til hakket kød, men man kan også lave en bovsteg eller bruge boven i gryderetter, asiatiske supper og langtidstilberedning på grill eller i ovn.`,
		introImage: null, 
		introCuttingInstructions: ``,
		introRecipe: null,
		subtopics: [
			{
				id: 'Bovsteg',
				name: 'Bovsteg',
				subcutImage: null,
				text: `Bovstegen indeholder både kød, ben, bindevæv og fedt, og derfor skal bovstegen tilberedes længe for at få et mørt og saftigt resultat.

				Bovstegen kan også skæres ud tykke skiver og steges på panden som en steak.`,
				video: null,
				recipe: require('data/recipes/bov/bovsteg.md').default, 
				image: 'bov_steg.jpg',
				cuttingInstructions: ``,
				funFact: ``,
				sustainability: ``
			},
			{
				id: 'Hakket svinekød',

				name: 'Hakket svinekød',
				text: `Kødhakkeren blev opfundet i 1850. Før det, var det kun det fornemme herskab, der spiste hakket kød, for det tog lang tid at hakke kødet med en kniv. Da kødhakkeren kom til gik det stærkt med at få frikadeller, kåldolmere og andre farsretter på menuen.`,
				video: require('assets/videos/Vidste_du_Frikadeller_1.mp4').default,
				
				recipe: require('data/recipes/detail/hakkket-svinekød.md').default, 
				image: 'hakket_8_procent_fedt.jpg',
				funFact: null,
				cuttingInstructions: `Hakket kød laves af afskær og restkød fra grisen. Særligt på grisens bov, skank og skinke får man kødrester, som ikke indgår i andre udskæringer, og som kan kommes i kødhakkeren. Husk at tilsætte 7-8% fedt. Svinekød er i sig selv meget magert, så uden fedt i farsen kan det blive nogle tørre frikadeller.`,
				sustainability:`Stop madspild: 
				Hakket kød har en kort holdbarhed, så brug det straks eller frys det ned til senere brug.
				Forklaring: 
				Efter slagtning er de hele stykker kød sterile i midten. Det er derfor kun på overfladen af kødet, at bakterier kan vokse, og hvis man opbevarer kødet korrekt på køl, så spreder bakterierne sig kun langsomt. Men når man hakker kød, så ødelægges den sterile kerne, og bakterierne kan pludselig vokse og formere sig hurtigere.  Derfor - brug det hakkede kød hurtigt.`,
			},
		],
		funFact: ``,
		sustainability: ``
	},
];

const pigTopic = {
	id: 'grisen',
	name: 'Udskæringer',
	intro: `Grisen byder på mange udskæringer, der kan tilberedes på et hav af måder. 

	I grisen til højre finder du en beskrivelse af de vigtigste udskæringer på grisen. Du kan også se billeder af udskæringerne og finde lækre opskrifter.`,
	introImage: 'Udskæringsplanche.png',
	introRecipe: null,
	introCuttingInstructions: null,
	funFact: null,
	subtopics: []
};

const educationTopic = {
	id: 'education',
	name: 'Uddannelse',
	intro: 'Det kræver mange forskellige slags medarbejdere og kompetencer at drive et slagteri. <br/><br/>'+

	'Her kan du møde 3 vigtige uddannelser med gode jobmuligheder: <br/>'+
	'<ul>' +
	'<li> Slagter </li>' +
	'<li> Tarmrenser </li>' +
	'<li> Industrioperatør </li>' +
	'</ul><br/>'+
	
	'Klik på uddannelserne, og læs mere. <br/><br/>'+
	
	'Hvis en af uddannelserne fanger din interesse, så kan din underviser hjælpe dig med at tage kontakt til en virksomhed i nærheden af dig. På den måde kan du få mere information og måske komme i praktik. <br/><br/>'+  
	
	'Hvis du lyst til at høre om flere uddannelser indenfor fødevareområdet, så kan du læse mere på <a href="www.futurefood.nu">www.futurefood.nu</a>',
	funFact: `Ca. 190.000 mennesker i Danmark har et job i jordbrugs- og fødevarebranchen.`,
};

export {pigCuts, pigTopic, educationTopic};