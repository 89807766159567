import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import {ReactComponent as PigImage} from 'assets/images/the-pig.svg';
import {generalUiTexts, settingsUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import themesData from 'data/themes-data';
import './settings.scss';

const Settings = ({
	game,
	continueGame,
	startNewGame,
	createNewGame,
	chooseIsEasy,
	isEasyState,
	chooseTheme,
	themeIdsState,
	feedback,
	isCreatingGame,
	showSettings
}) => {
	let themes = game ? themesData.filter((theme) => {return game.themeIds.includes(theme.id);}) : themesData;
	// let isEasy = game ? game.isEasy : isEasyState;

	let text = null;
	if (showSettings) {
		// if (!game) {
		// 	text = settingsUiTexts.chooseThemes;
		// }
	} else {
		text = renderMarkdown(settingsUiTexts.gameExists);
	}

	let className = 'Settings';
	let content = null;
	if (showSettings) {
		content = <React.Fragment>
			<div className={'Settings-settings' + (game ? ' locked' : '')}>
				{/* <div className="Settings-difficultyWrap">
					<div>{settingsUiTexts.level + ': '}</div>
					<span className={'Settings-difficultyBtn' + (isEasy ? ' selected' : '')}
						onClick={() => {chooseIsEasy(true);}}>{settingsUiTexts.easy}</span>
					<span className={'Settings-difficultyBtn' + (!isEasy ? ' selected' : '')}
						onClick={() => {chooseIsEasy(false);}}>{settingsUiTexts.difficult}</span>
				</div> */}
				{game && <div className="Settings-gameCode">
					{(settingsUiTexts.gameCode + ': ')}
					<div>{game.id}</div>
				</div>}
				{game && <div className="Settings-info">
					<p>{settingsUiTexts.gameExistsInfo1}</p>
					<p>{settingsUiTexts.gameExistsInfo2}</p>
				</div>}
				<div className="Settings-themes">
					<h3>{settingsUiTexts.themes}</h3>
					{themes.map((theme, i) => {
						return <div key={i}
							className={'Settings-theme ' + (themeIdsState.includes(theme.id) ? ' selected' : '')}
							onClick={() => {chooseTheme(theme.id);}}>
							<div className="Settings-themeName">Tema {theme.id}: {theme.name}</div>
							{/* <div className="Settings-themeIntro">{theme.intro}</div> */}
						</div>;
					})}
				</div>
				{!game && <Button
					text={settingsUiTexts.startNewGame}
					onClick={createNewGame}
					isLoading={isCreatingGame}
				/>}
				{/* <Button text={settingsUiTexts.startNewGame} onClick={createNewGame} isLoading={isCreatingGame}/> */}
				<div className="Settings-feedback">{feedback}</div>
			</div>
		</React.Fragment>;
	} else {
		className += ' Settings--block';
		content = <React.Fragment>
			<Button text={settingsUiTexts.continueGame} onClick={continueGame}/>
			<Button text={settingsUiTexts.startNewGame} onClick={createNewGame} isLoading={isCreatingGame}/>
		</React.Fragment>;
	}

	return (
		<div className={className}>
			<div className="Settings-genericInfo">
				<h2 className="Settings-gameTitle">{generalUiTexts.title}</h2>
				<div className="Settings-text">{text}</div>
				<PigImage className="Settings-pigImage" />
			</div>
			{content}
		</div>
	);
};

Settings.propTypes = {
	continueGame: PropTypes.func.isRequired,
	startNewGame: PropTypes.func.isRequired,
	createNewGame: PropTypes.func.isRequired,
	chooseIsEasy: PropTypes.func.isRequired,
	isEasyState: PropTypes.bool.isRequired,
	chooseTheme: PropTypes.func.isRequired,
	themeIdsState: PropTypes.array.isRequired,
	feedback: PropTypes.string.isRequired,
	isCreatingGame: PropTypes.bool.isRequired,
	showSettings: PropTypes.bool.isRequired,
	game: PropTypes.object
};

export default Settings;
