import React from 'react';
import PropTypes from 'prop-types';
import {challengeUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Popup from 'components/popup/popup';
import './quiz.scss';

const Quiz = (props) => {
	let {
		isPaused, 
		playerHasSeenClueWarning,
		showConfirmGetCluePopup, 
		showImagePopup,
		quizData, 
		toggleCluePopup, 
		toggleImagePopup,
		handleGetClue,
		handleSelectAnswer
	} = props;

	let numberOfCorrectAnswers = quizData.answers.filter((answer) => {return answer.isCorrect === true;}).length;
	let numberOfRevealedClues = (quizData.clues 
		? quizData.clues.filter((clue) => {return clue.isRevealed;}).length
		: 0
	);

	let imageThumb = null;
	if (
		quizData.question.image !== null &&
		quizData.question.image.length > 0 &&
		quizData.question.showImageInPopup
	) {
		imageThumb = require('assets/images/' + quizData.question.image).default;
	}

	return (
		<div className={'Quiz Quiz--' + quizData.id + (isPaused ? ' Quiz--paused' : '')}>

			{/* Quiz header */}
			<div id="QuizHeader" className="Quiz-header">
				<div className="Quiz-headerContent">
					{/* Question & image thumbnail*/}
					<div className={'Quiz-question' + (imageThumb ? ' Quiz-question--left' : '')}>
						{/* Instructions */}
						{quizData.question.showNumberOfAnswers &&
								<div className="Quiz-instructions">
									{challengeUiTexts.choose} {numberOfCorrectAnswers} {challengeUiTexts.answers}
								</div>}
						{renderMarkdown(quizData.question.text)}
					</div>
					{/* Clues */}
					{quizData.clues && 
						<div className="Quiz-clues">
							{numberOfRevealedClues > 0 && <div className="Quiz-clueInfo">
								<span>{challengeUiTexts.hintTitle}:</span>
							</div>}
							{quizData.clues.map((clue, index) => {
								return (clue.isRevealed 
									? <div key={index} className="Quiz-clueText">{renderMarkdown(clue.text)}</div> 
									: null
								);
							})}
							{(!quizData.completed && quizData.clues.some((clue) => {return !clue.isRevealed;})) && 
								<div 
									className="Quiz-getClueBtn"
									onClick={() => {
										if (playerHasSeenClueWarning) {
											handleGetClue();
										} else {
											toggleCluePopup(true);
										}
									}}
									dangerouslySetInnerHTML={{__html: (numberOfRevealedClues > 0 
										? challengeUiTexts.getAnotherHint 
										: challengeUiTexts.getAHint
									)}}
								/>
							}
						</div>
					}


					{imageThumb && 
							<div className="Quiz-imageThumb" onClick={() => {toggleImagePopup(true);}}>
								<img src={imageThumb} alt={imageThumb} />
								{/* <div className="Quiz-imageThumbZoom" /> */}
							</div>
					}	
				</div>
			</div>

			{/* Quiz body */}
			<div id="QuizBody" className="Quiz-body">
				{/* Image */}
				{(quizData.question.image && !quizData.question.showImageInPopup) &&
					<div className={'Quiz-image Quiz-image--' + quizData.id} />
				}

				{/* Answers */}
				<div className="Quiz-answers">
					{quizData.answers.map((answer, index) => {
						let className = 'Quiz-answer';
						if (answer.isSelected) {
							if (!quizData.completed) {
								className = className + ' selected ' 
									+ (answer.isCorrect ? 'animateCorrect' : 'animateWrong');
							} else {
								className = className + ' ' + (answer.isCorrect ? 'correct' : 'wrong');
							}
						}
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {if (!isPaused && !answer.isSelected) handleSelectAnswer(answer.id);}}
							>{renderMarkdown(answer.text)}</div>
						);
					})}
				</div>
			</div>

			<Popup
				showPopup={showConfirmGetCluePopup}
				type="getClue"
				title={challengeUiTexts.getCluePopup.title}
				text={challengeUiTexts.getCluePopup.text}
				video={null}
				image={null}
				buttons={[
					{text: challengeUiTexts.getCluePopup.buttons.ok, action: handleGetClue, params: []},
					{text: challengeUiTexts.getCluePopup.buttons.cancel, action: toggleCluePopup, params: [false]} 
				]}
			/>

			<Popup
				showPopup={showImagePopup}
				frameText={true}
				type="quizImage"
				title={null}
				text={null}
				image={quizData.question.image}
				video={null}
				onClose={() => {toggleImagePopup(false);}}
			/>
		</div>
	);
};

Quiz.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	playerHasSeenClueWarning: PropTypes.bool.isRequired,
	showConfirmGetCluePopup: PropTypes.bool.isRequired,
	showImagePopup: PropTypes.bool.isRequired,
	quizData: PropTypes.object.isRequired,
	toggleCluePopup: PropTypes.func.isRequired,
	toggleImagePopup: PropTypes.func.isRequired,
	handleGetClue: PropTypes.func.isRequired,
	handleSelectAnswer: PropTypes.func.isRequired
};

export default Quiz;