import React from 'react';
import PropTypes from 'prop-types';
import './page.scss';

const Page = (props) => {
	return (
		<div className={'Page' + (props.isTeacher ? ' Page--teacher' : ' Page--student')}>
			<div className="LogoutBtn" onClick={() => {props.handleLogout();}}>Log ud</div>
			{props.children}
		</div>
	);
};

Page.propTypes = {
	children: PropTypes.any.isRequired,
	handleLogout: PropTypes.func.isRequired,
	isTeacher: PropTypes.bool.isRequired
};

export default Page;