import React from 'react';
import PropTypes from 'prop-types';
// import {challengeUiTexts} from 'data/ui-texts';
import ThemeProgress from 'components/theme-progress/theme-progress';
import QuizController from './quiz/quiz-controller';
import PairsController from './pairs/pairs-controller';
import SortController from './sort/sort-controller';
import OrderController from './order/order-controller';
import './challenge.scss';

const Challenge = (props) => {
	let {
		// challengeCompleted,
		// isLastChallengeInTheme,
		themeId,
		themeData,
		challengeIndex,
		themeChallengesData,
		playerData,
		completeChallenge,
		updatePlayerData,
		goToChallenge,
		// goToNextChallenge,
		// handleThemeComplete
	} = props;

	let Component = <div>Unknown challenge: {themeChallengesData[challengeIndex].type}</div>;
	if (themeChallengesData[challengeIndex].type === 'quiz') Component = QuizController;
	if (themeChallengesData[challengeIndex].type === 'pairs') Component = PairsController;
	if (themeChallengesData[challengeIndex].type === 'sort') Component = SortController;
	if (themeChallengesData[challengeIndex].type === 'order') Component = OrderController;

	return (
		<div className={'Challenge Challenge--' + themeChallengesData[challengeIndex].type}>
			{/* Challenge header */}
			<div className="Challenge-header">
				<div className="Challenge-themeName">{themeData.name}</div>
				<ThemeProgress
					themeChallenges={themeChallengesData}
					goToChallenge={goToChallenge}
					currentChallengeIndex={challengeIndex}
				/>
			</div>
			
			{/* Challenge body */}
			<div className="Challenge-body">
				<Component
					themeId={themeId}
					challengeData={themeChallengesData[challengeIndex]}
					playerData={playerData}
					completeChallenge={completeChallenge}
					updatePlayerData={updatePlayerData}
				/>

				{/* {challengeCompleted &&
					<div className="Challenge-btn"
						onClick={() => {isLastChallengeInTheme ? handleThemeComplete() : goToNextChallenge();}}>
						{challengeUiTexts.next}
					</div>} */}
			</div>
		</div>
	);
};

Challenge.propTypes = {
	// challengeCompleted: PropTypes.bool.isRequired,
	// isLastChallengeInTheme: PropTypes.bool.isRequired,
	themeId: PropTypes.number.isRequired,
	themeData: PropTypes.object.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	themeChallengesData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	goToChallenge: PropTypes.func.isRequired,
	// goToNextChallenge: PropTypes.func,
	// handleThemeComplete: PropTypes.func.isRequired,
};

export default Challenge;