import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import {loginUiTexts, generalUiTexts} from 'data/ui-texts';
import LoginTeacherController from './login-teacher-controller';
import RequestPasswordController from './request-password-controller';
import LoginStudentController from './login-student-controller';
import { ReactComponent as PigImage } from 'assets/images/the-pig.svg';
import './login.scss';

const Login = ({activeLoginBox, setActiveLoginBox}) => {
	/* Get active box component for teacher box */
	let [teacherBoxType, setTeacherBoxType] = useState('login');

	let TeacherBoxComponent = LoginTeacherController;
	if (teacherBoxType === 'resetPassword') TeacherBoxComponent = RequestPasswordController;

	return (
		<div className="Login">
			<div className="Login-wrap">
				<h2 className="Login-title">{generalUiTexts.title}</h2>
				<PigImage className="PigImageLogin"/>
				<div className="Login-box">
					<div className="Login-tabContainer">
						<div
							className={'Login-tabButton' + (activeLoginBox === 'student' ? ' active' : '')}
							onClick={() =>{setActiveLoginBox('student');}}
						><span>{loginUiTexts.student}</span></div>
						<div
							className={'Login-tabButton' + (activeLoginBox === 'teacher' ? ' active' : '')}
							onClick={() =>{setActiveLoginBox('teacher');}}
						><span>{loginUiTexts.teacher}</span></div>
					</div>
					<div className="Login-formContainer">
						{activeLoginBox === 'teacher' &&
							<TeacherBoxComponent
								setTeacherBoxType={setTeacherBoxType}
							/>}
						{activeLoginBox === 'student' &&
							<LoginStudentController />
						}
					</div>
				</div>
				
			</div>
			<div className='Login-logo-wrap'>
				<div className='Login-LF-logo'/>
				<div className='Login-cgl-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			</div>
			
			<CookieConsentController />
		</div>
	);
};

Login.propTypes = {
	activeLoginBox: PropTypes.string.isRequired,
	setActiveLoginBox: PropTypes.func.isRequired
};

export default Login;