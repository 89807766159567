let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
// if (env === 'test' || env === 'demo') {
// 	gameUrl = 'cgl-digitalgris-test.web.app';
// 	apiURL = 'https://cgl-digitalgris-test.web.app/api/';
// }

if (env === 'production') {
	gameUrl = 'cgl-digitalgris-production.web.app';
	apiURL = 'https://cgl-digitalgris-production.web.app/api/';
}

let gamesDbName = 'games';
let studentsDbName = 'students';
let usersDbName = 'users';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-digitalgris-test.web.app';
	apiURL = 'https://cgl-digitalgris-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-digitalgris-demo.web.app';
		apiURL = 'https://cgl-digitalgris-demo.web.app/api/';
		gamesDbName = 'games-demo';
		studentsDbName = 'students-demo';
		usersDbName = 'users';
	}
}

const appConfig = {
	env: env,
	name: 'digitalgris',
	apiURL: apiURL,
	gameUrl: gameUrl,
	useStreaks: false,
	limitStreaksPerTheme: false,
	cookiesAcceptedCookieName: 'den_digitale_gris_cookies_accepted',
	gamesDbName,
	studentsDbName,
	usersDbName
};

export default appConfig;
