import React from 'react';
import PropTypes from 'prop-types';
import Settings from './settings';
import errorTexts from 'data/error-texts';
import apiHelper from 'helpers/api-helper';
import themesData from 'data/themes-data';
class SettingsController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			game: null,
			isEasy: true,
			themeIds: [],
			feedback: '',
			isCreatingGame: false,
			showSettings: true
		};
	}

	componentDidMount() {
		if (this.props.game) {
			this.setState({
				game: this.props.game,
				showSettings: false,
			});
		}
	}

	continueGame = () => {
		this.setState({
			showSettings: true,
		});
	}

	startNewGame = () => {
		this.setState({
			game: null,
			isEasy: true,
			themeIds: [],
			showSettings: true
		});
	}

	createNewGame = () => {
		if (this.state.isCreatingGame) return;
		// if (this.state.themeIds.length === 0) {
		// 	this.setState({feedback: errorTexts.themeNotChosen});
		// } else {
		// let fetchThemes = JSON.parse(JSON.stringify(themesData));
		let ids = JSON.parse(JSON.stringify(themesData.map((theme) => {return theme.id;})));
		this.setState({isCreatingGame: true}, () => {
			apiHelper('teacher/create-game', {
				userId: this.props.userId,
				isEasy: this.state.isEasy,
				// themeIds: [this.state.themeIds]
				themeIds: ids
			}).then(
				(data) => {
					// Success
					this.setState({
						isCreatingGame: false,
						game: data.game,
						showSettings: true
					});
				},
				(rejection) => {
					// Error
					console.error(rejection);
					this.setState({
						isCreatingGame: false,
						feedback: errorTexts.unknownError
					});
				}
			);
		});
		// }
	}

	chooseIsEasy = (isEasy) => {
		if (this.state.game === null) {
			this.setState({
				isEasy: isEasy
			});
		}
	}

	chooseTheme = (themeId) => {
		if (this.state.game === null) {
			let themeIds = JSON.parse(JSON.stringify(this.state.themeIds));
			if (themeIds.includes(themeId)) {
				themeIds.splice(themeIds.indexOf(themeId), 1);
			} else {
				themeIds.push(themeId);
			}
			this.setState({themeIds, feedback: ''});
		}
	}

	/* Render component */
	render() {
		return (
			<Settings
				continueGame={this.continueGame}
				startNewGame={this.startNewGame}
				createNewGame={this.createNewGame}
				chooseIsEasy={this.chooseIsEasy}
				isEasyState={this.state.isEasy}
				chooseTheme={this.chooseTheme}
				themeIdsState={this.state.themeIds}
				feedback={this.state.feedback}
				isCreatingGame={this.state.isCreatingGame}
				gameCode={this.state.gameCode}
				showSettings={this.state.showSettings}
				game={this.state.game}
			/>
		);
	}
}

SettingsController.propTypes = {
	game: PropTypes.object,
	userId: PropTypes.string.isRequired
};

export default SettingsController;