import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {challengeUiTexts} from 'data/ui-texts';
import './order.scss';

const Order = ({isPaused, selectedItemId, animation, orderData, handleSelectItem, handleSelectBox}) => {
	return (
		<div className={'Order Order--' + orderData.id + (isPaused ? ' Order--paused' : '')}>

			{/* Order header */}
			<div id="OrderHeader" className="Order-header">
				{/* Question */}
				<div className="Order-question">{challengeUiTexts.orderInstruction}</div>
				<div className="Order-question">{renderMarkdown(orderData.question.text)}</div>
				
				{/* Boxes */}
				<div className="Order-boxes">
					{orderData.boxes.map((box, index) => {
						let text = box.name;
						let className = 'Order-boxContainer Order-boxContainer--' + (index + 1);
						if (Array.isArray(box.correctItemId)) {
							className += ' Order-boxContainer--' +
								(box.correctItemId.includes(box.placedItemId) ? 'correct' : 'wrong');
						} else {
							className += ' Order-boxContainer--' +
								(box.placedItemId === box.correctItemId ? 'correct' : 'wrong');
						}
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {if (!isPaused && box.placedItemId === null) handleSelectBox(index);}}
							>
								<div className="Order-boxText">{text}</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Order body */}
			<div id="OrderBody" className="Order-body">
				{/* Items */}
				<div className="Order-items">
					{orderData.items.map((item, index) => {
						let className = 'Order-item';
						let boxIndex = orderData.boxes.findIndex((box) => {return box.placedItemId === item.id;});
						let isPlaced = boxIndex >= 0; 
						if (orderData.completed) {
							className = className + ' Order-item--placed';
						} else {
							if (item.id === selectedItemId) {
								if (animation) {
									className = className + ' Order-item--' + animation;
								} else {
									className = className + ' Order-item--selected';
								}
							} else {
								if (isPlaced) className = className + ' Order-item--placed';
							}
						}
						return (
							<div key={index} className="Order-itemWrap">
								<div 
									className={className}
									onClick={() => {if (!isPaused && !isPlaced) handleSelectItem(item.id);}}
								>{renderMarkdown(item.name)}</div>
								{(isPlaced && item.id !== selectedItemId) && 
									<div className="Order-itemNumber">{boxIndex + 1}</div>}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Order.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	selectedItemId: PropTypes.string,
	animation: PropTypes.string,
	orderData: PropTypes.object.isRequired,
	handleSelectItem: PropTypes.func.isRequired,
	handleSelectBox: PropTypes.func.isRequired
};

export default Order;