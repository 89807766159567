import {icons} from 'data/highscore-data';
import {quizPoints, singlePairPoints, multiplePairsPoints, orderPoints, sortPoints} from 'data/points-data';

export function getChallengePoints(challenge) {
	let points = 0;
	if (challenge.type === 'quiz') points = (challenge.clues ? quizPoints.basePointsWithClues : quizPoints.basePoints);
	if (challenge.type === 'pairs') {
		let numberOfPairs = challenge.columnA.filter((card) => {
			return card.hasOwnProperty('correctConnectionId');
		}).length;
		points = (numberOfPairs === 1 ? singlePairPoints : multiplePairsPoints).pointValues[0];
	}
	if (challenge.type === 'order') points = orderPoints.pointValues[0];
	if (challenge.type === 'sort') points = sortPoints.pointValues[0];
	
	return points;
}

export function getIconData(points) {
	let iconData = icons.find((icon) => {
		if (icon.hasOwnProperty('pointsMin') && icon.hasOwnProperty('pointsMax')) {
			if (points >= icon.pointsMin && points <= icon.pointsMax) return true;
			return false;
		}
		if (icon.hasOwnProperty('pointsMin') && points >= icon.pointsMin) return true;
		if (icon.hasOwnProperty('pointsMax') && points <= icon.pointsMax) return true;
		return false;
	});

	return iconData;
}

export function getIconType(points) {
	let iconType = 'none';
	const iconData = getIconData(points);
	if (iconData) iconType = iconData.id;

	return iconType;
}


export function checkIfRoomStreakUnlocked(roomChallengesData, playerData) {
	let errors = 0;
	roomChallengesData.forEach((challenge) => {
		if (errors > 0) return;
		if (!playerData.challenges.some((playerChallenge) => {return playerChallenge.id === challenge.id;})) return;

		let playerChallengeData = playerData.challenges.find((playerChallenge) => {
			return playerChallenge.id === challenge.id;
		});

		if (challenge.type === 'quiz') {
			let wrongAnswers = challenge.answers.filter((answer) => {
				return (answer.isCorrect === false && playerChallengeData.selectedAnswers.indexOf(answer.id) >= 0);
			}).length;
			errors = errors + wrongAnswers;
		}
		if (challenge.type === 'sort' || challenge.type === 'order' || challenge.type === 'pairs') {
			errors = errors + playerChallengeData.errors;
		}
	});
	return (errors === 0);
};

export function getPointsIllustrationData(themeChallengesData, playerData) {
	let totalThemePoints = themeChallengesData.reduce((prev, curr) => {return prev + getChallengePoints(curr);}, 0);
	let playerThemePoints = playerData.challenges
		.filter((challenge) => {return themeChallengesData.some((c) => {return c.id === challenge.id;});})
		.reduce((prev, curr) => {return curr.points ? prev + curr.points : prev;}, 0);
	
	let greenFilling = playerThemePoints * 100 / totalThemePoints;
	let filledDivisions = [
		greenFilling >= 1 * 22.5,
		greenFilling >= 2 * 25 - 1.5,
		greenFilling >= 3 * 25 - 1,
		greenFilling >= 4 * 25
	];

	return {greenFilling, filledDivisions};
}