import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';

const LoginStudent = (props) => {
	const {
		isLoading,
		code,
		feedback,
		handleInput,
		handleFindGame,
	} = props;

	/* Input game code */
	return (
		<div className="LoginStudent">
			<form className="Login-form" onSubmit={handleFindGame}>
				<input
					name="code"
					type="text"
					placeholder={loginUiTexts.gameCode}
					autoComplete="section-studentlogin code"
					value={code ? code : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input 
					className={'Button Button--login' + (isLoading ? ' Button--isLoading' : '')}
					type="submit"
					value={loginUiTexts.login} 
				/>
				<div className="Login-errorMessage">{feedback}</div>
			</form>
		</div>
	);
};

LoginStudent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	code: PropTypes.any,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
};

export default LoginStudent;