/* TEMPLATE
{
	id: '',
	type: 'quiz',
	themeId: null,
	challengeNumber: null,
	hint: {
		location: '',
		topic: '',
		subtopic: 0,
		popup: '',
		isInfoBoxText: false
	},
	question: {
		text: '',
		showNumberOfAnswers: false,
		image: null,
		showImageInPopup: false
	},
	answers: [
		{id: 1, text: '', isSelected: false, isCorrect: false},
		{id: 2, text: '', isSelected: false, isCorrect: false},
		{id: 3, text: '', isSelected: false, isCorrect: false}
	],
	clues: [
		{id: 1, text: '', isRevealed: true},
		{id: 2, text: '', isRevealed: false},
		{id: 3, text: '', isRevealed: false},
	],
	firstClueIsFree: true
},
*/

const quizzesData = [
	{
		id: 'quiz-1',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 2,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
		},
		question: {
			text: 'Hvad kaldes den handske, som slagteren har på?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Jernhånd', isSelected: false, isCorrect: false},
			{id: 2, text: 'Skærehandske', isSelected: false, isCorrect: true},
			{id: 3, text: 'Sikkerhedshandske', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-2',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 3,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			popup: 'video',
			isInfoBoxText: false
		},
		question: {
			text: 'Hvad synes slagtereleven Lasse er det mest spændende ved at arbejde i kødbranchen i Danmark?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Han kan godt lide håndværket og at få lov til at bruge sine hænder', isSelected: false, isCorrect: true},
			{id: 2, text: 'Han kan godt lide at stå tidligt op og få noget ud af dagen', isSelected: false, isCorrect: false},
			{id: 3, text: 'Han kan godt lide sammenholdet med sine kollegaer', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-3',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 4,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 2,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
			// isFunFact: true,
		},
		question: {
			text: 'Hvilket udsagn er korrekt?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Jeg må gerne tage mit hårnet af, hvis det klør i håret', isSelected: false, isCorrect: false},
			{id: 2, text: 'Jeg må gerne tage mit hårnet af, hvis det er træls at have på', isSelected: false, isCorrect: false},
			{id: 3, text: 'Hårnettet skal bæres hele dagen, men ikke i pauser', isSelected: false, isCorrect: false},
			{id: 4, text: 'Hårnet skal altid bæres og bæres hele dagen også i pauserne', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-4',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 5,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 3,
			popup: null,
			isInfoBoxText: true,
		},
		question: {
			text: 'Hvilket udsagn er korrekt?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: "Slagterierne blev først rigtig store i løbet af 1970´erne. Kvindernes indtog på arbejdsmarkedet gjorde, at de havde mindre tid i køkkenet. Derfor skulle kødet komme i specifikke udskæringer", isSelected: false, isCorrect: false},
			{id: 2, text: "Slagterierne har rødder tilbage til 1800'tallet, hvor danske landmænd gik sammen om etableringen af verdens første andelsselskaber", isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-5',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 6,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 1,
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvad kendetegner en slagters hverdag?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Struktur, tempo og forudsigelighed', isSelected: false, isCorrect: true},
			{id: 2, text: 'Ventetid, tålmodighed og fysisk arbejde', isSelected: false, isCorrect: false},
			{id: 3, text: 'Mange skift, samarbejde og kreativ problemløsning', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-6',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 7,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 3,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvad vejer slagterens fulde udstyr?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '2 kg', isSelected: false, isCorrect: false},
			{id: 2, text: '4 kg', isSelected: false, isCorrect: true},
			{id: 3, text: '6 kg', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-7',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 10,
		hint: {
			location: 'butchery',
			topic: 'engine-room',
			subtopic: 3,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilken maskine bruger man til at dele grisen i to?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Robotsav', isSelected: false, isCorrect: true},
			{id: 2, text: 'Bovbladsmaskine', isSelected: false, isCorrect: false},
			{id: 3, text: 'Tre-deler-robot', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-8',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 12,
		hint: {
			location: 'education',
			topic: 'butcher2',
			subtopic: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: true
		},
		question: {
			text: 'Hvor mange slagterimedarbejdere er der i Danmark, og hvad tjener de i gennemsnit?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ca. 7000 slagterimedarbejdere, der tjener 203,95 kr/t i gennemsnit', isSelected: false, isCorrect: false},
			{id: 2, text: 'Ca. 5000 slagterimedarbejdere, der tjener 256,50 kr/t. i gennemsnit', isSelected: false, isCorrect: false},
			{id: 3, text: 'Ca. 6392 slagterimedarbejdere, der tjener 294,21 kr/t i gennemsnit', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-9',
		type: 'quiz',
		themeId: 1,
		challengeNumber: 13,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 1,
			subtopic2: 1,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true,
		},
		question: {
			text: 'Hvilken arbejdsstation(er) på slagteriet skaber mest støjbelastning?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Vaskeplads, køling og ekspedition', isSelected: false, isCorrect: true},
			{id: 2, text: 'Opskæring og tarmrenseri', isSelected: false, isCorrect: false},
			{id: 3, text: 'Ved den sorte slagtegang', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	// {
	// 	id: 'quiz-4',
	// 	type: 'quiz',
	// 	themeId: 1,
	// 	challengeNumber: 5,
	// 	hint: {
	// 		location: 'butchery',
	// 		topic: 'butcher',
	// 		subtopic: 1,
	// 		popup: 'video',
	// 		isInfoBoxText: false
	// 	},
	// 	question: {
	// 		text: 'Hvad ville Lasse sige til sin ven, hvis han skulle anbefale ham at arbejde i kødbranchen?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Hvis han godt kan lide at bruge sine fingre og lide at stå tidligt op, så er slagter også noget for ham', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'Hvis han godt kan lide at sidde stille hele dagen og kigge på en computer, så er slagter også noget for ham', isSelected: false, isCorrect: false},
	// 	],
	// 	clues: null
	// },
	{
		id: 'quiz-29',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 1,
		hint: {
			location: 'education',
			topic: 'butcher2',
			subtopic: 3,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvor i landet kan man uddanne sig til slagter?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Der findes flere uddannelsesinstitutioner rundt om i Danmark', isSelected: false, isCorrect: false},
			{id: 2, text: 'I Roskilde', isSelected: false, isCorrect: true},
			{id: 3, text: 'I Aarhus', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-30',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 2,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 1,
			popup: 'video',
			isInfoBoxText: false
		},
		question: {
			text: 'Hvad er en god dag på slagteriet for Lasse? Vælg flere svar',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'En dag uden stop', isSelected: false, isCorrect: true},
			{id: 2, text: 'Når en ny udskæring sidder lige i øjet', isSelected: false, isCorrect: false},
			{id: 3, text: 'Når han har haft en god dag sammen med kollegaerne', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-10',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 3,
		hint: {
			location: 'butchery',
			topic: 'bryst',
			subtopic: 'bacon',
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvor på grisen kommer bacon fra?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Brystet', isSelected: false, isCorrect: true},
			{id: 2, text: 'Maven', isSelected: false, isCorrect: false},
			{id: 3, text: 'Skulderen', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-11',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 4,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: null,
			popup: null,
			isFunFact: true
		},
		question: {
			text: 'Til hvor mange lande eksporteres grisens udskæringer til?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '130', isSelected: false, isCorrect: false},
			{id: 2, text: '99', isSelected: false, isCorrect: false},
			{id: 3, text: '127', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-12',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 5,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor lang tid tager det at slide en slagterkniv op?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Cirka 3 dage', isSelected: false, isCorrect: false},
			{id: 2, text: 'Cirka 1 måned', isSelected: false, isCorrect: true},
			{id: 3, text: 'Cirka 8 måneder', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-13',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 6,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 1,
			popup: 'image',
			isInfoBoxText: true,
		},
		question: {
			text: 'Hvilken arbejdsstation(er) på slagteriet skaber mest miljøbelastning i form af spildevand?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Sort slagtegang og tarmrenseri', isSelected: false, isCorrect: true},
			{id: 2, text: 'Vaskeplads og opskæring', isSelected: false, isCorrect: false},
			{id: 3, text: 'Køling og ekspedition', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-14',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 8,
		hint: {
			location: 'butchery',
			topic: 'bryst',
			subtopic: 'bacon',
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvornår begyndte vi at spise bacon?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '7000 år f.kr', isSelected: false, isCorrect: true},
			{id: 2, text: 'I 1800-tallet', isSelected: false, isCorrect: false},
			{id: 3, text: 'I 1000-tallet', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-15',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 9,
		hint: {
			location: 'butchery',
			topic: 'loin',
			subtopic: 'roast-pork',
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor skæres flæskestegen fra?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Boven', isSelected: false, isCorrect: false},
			{id: 2, text: 'Kammen', isSelected: false, isCorrect: true},
			{id: 3, text: 'Brystet', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-16',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 10,
		hint: {
			location: 'butchery',
			topic: 'bryst',
			subtopic: 'bacon',
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvad er verdensrekorden i baconspisning på 5 min.?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '89 skiver', isSelected: false, isCorrect: false},
			{id: 2, text: '142 skiver', isSelected: false, isCorrect: false},
			{id: 3, text: '182 skiver', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-17',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 11,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 1,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvor lang tid tager det at slibe en slagtekniv?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '6 minutter', isSelected: false, isCorrect: false},
			{id: 2, text: '36 sekunder', isSelected: false, isCorrect: true},
			{id: 3, text: '2 minutter', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-31',
		type: 'quiz',
		themeId: 2,
		challengeNumber: 13,
		hint: {
			location: 'butchery',
			topic: 'indmad',
			subtopic: 'hjerte',
			popup: null,
			isInfoBoxText: true
		},
		question: {
			text: 'Hvilken type ret egner svinehjerter sig særligt godt til?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'I en simreret', isSelected: false, isCorrect: true},
			{id: 2, text: 'I en suppe', isSelected: false, isCorrect: false},
			{id: 3, text: 'I en wokret', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-25',
		type: 'quiz',
		themeId: 3,
		challengeNumber: 1,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvor meget vejer en slagtegris?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'ca. 75 kg', isSelected: false, isCorrect: false},
			{id: 2, text: 'ca. 90 kg', isSelected: false, isCorrect: false},
			{id: 3, text: 'ca. 110 kg', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-18',
		type: 'quiz',
		themeId: 3,
		challengeNumber: 3,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 2,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor mange gange må en økologisk gris få medicin?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '1 gang', isSelected: false, isCorrect: true},
			{id: 2, text: '3 gange', isSelected: false, isCorrect: false},
			{id: 3, text: '5 gange', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-19',
		type: 'quiz',
		themeId: 3,
		challengeNumber: 4,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor mange forskellige typer af stalde er en slagtegris i inden den bliver slagtet?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '1 stald', isSelected: false, isCorrect: false},
			{id: 2, text: '3 stalde', isSelected: false, isCorrect: true},
			{id: 3, text: '4 stalde', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-26',
		type: 'quiz',
		themeId: 3,
		challengeNumber: 6,
		hint: {
			location: 'farm',
			topic: 'farmer-life',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvilket dyr blev først gjort tamt?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Hunden', isSelected: false, isCorrect: true},
			{id: 2, text: 'Grisen', isSelected: false, isCorrect: false},
			{id: 3, text: 'Æslet', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-20',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 1,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 3,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvad var årsagen til, at den danske svineproduktion voksede i slut 1800-tallet?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Stigende velstand i Tyskland', isSelected: false, isCorrect: false},
			{id: 2, text: 'England åbnede for muligheden af eksport af forædlede kødvarer', isSelected: false, isCorrect: true},
			{id: 3, text: 'Den fransk-preussiske krig ødelagde Frankrigs fødevareproduktion', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-21',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 2,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor meget har slagterierne i Danmark reduceret deres vandforbrug de sidste 15 år?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '25 %', isSelected: false, isCorrect: false},
			{id: 2, text: '50 %', isSelected: false, isCorrect: true},
			{id: 3, text: '75 %', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-27',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 3,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvordan har slagterierne reduceret deres vandforbrug? Vælg to svar.',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ved at strømline processer', isSelected: false, isCorrect: true},
			{id: 2, text: 'Ved at genbruge procesvandet', isSelected: false, isCorrect: false},
			{id: 3, text: 'Ved investering i nyt teknologisk udstyr', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-28',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 4,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 2,
			subtopic2: 1,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvad bruges vandet primært til på slagteriet?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Til rengøring for at opnå en god fødevaresikkerhed', isSelected: false, isCorrect: true},
			{id: 2, text: 'Recirkulering for at skabe opvarmning ', isSelected: false, isCorrect: false},
			{id: 3, text: 'I forbindelse med slagtning af grisen', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-22',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 5,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 3,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvor mange andelsslagterier og private slagterier er der tilbage i Danmark?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '7 andel og 1 privat', isSelected: false, isCorrect: false},
			{id: 2, text: '2 andel og 6 private', isSelected: false, isCorrect: false},
			{id: 3, text: '1 andel og 7 private', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-23',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 6,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvordan arbejdes der med bæredygtighedstiltag indenfor slagteribranchen?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ved fokus på klima- og energivenlige emballager', isSelected: false, isCorrect: true},
			{id: 2, text: 'Ved fokus på energieffektiv transport', isSelected: false, isCorrect: true},
			{id: 3, text: 'Tænke cirkulært', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-24',
		type: 'quiz',
		themeId: 4,
		challengeNumber: 7,
		hint: {
			location: 'butchery',
			topic: 'happens-at-butchery',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvad bruges de få restprodukter efter slagtningen til?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'De bliver brugt til biogas', isSelected: false, isCorrect: false},
			{id: 2, text: 'De bliver brugt til at producere medicin', isSelected: false, isCorrect: false},
			{id: 3, text: 'De bliver brugt til at producere energi', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-25',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 1,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 3,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvem har en mørk kraftig pels og vejer 180 kg.?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Landmanden', isSelected: false, isCorrect: false},
			{id: 2, text: 'Et vildsvin', isSelected: false, isCorrect: true},
			{id: 3, text: 'En Yorkshire gris', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-26',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 2,
		hint: {
			location: 'consumer',
			topic: 'archetypes',
			subtopic: 4,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvad er den største gruppe af arketyperne i Danmark?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'De grønne', isSelected: false, isCorrect: false},
			{id: 2, text: 'De traditionelle', isSelected: false, isCorrect: false},
			{id: 3, text: 'De bekvemme', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	
	{
		id: 'quiz-27',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 3,
		hint: {
			location: 'consumer',
			topic: 'detail',
			subtopic: 2,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilken befolkningsgruppe spiste før 1860 hakket svinekød?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Bonden', isSelected: false, isCorrect: false},
			{id: 2, text: 'De fornemme herskaber', isSelected: false, isCorrect: true},
			{id: 3, text: 'Arbejderklassen', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-28',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 4,
		hint: {
			location: 'consumer',
			topic: 'archetypes',
			subtopic: 3,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilken ret vil "den grønne" arketype vælge?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Selleribøf', isSelected: false, isCorrect: true},
			{id: 2, text: 'Flæskestegssandwich', isSelected: false, isCorrect: false},
			{id: 3, text: 'Hotdog', isSelected: false, isCorrect: false},
			{id: 4, text: 'Pasta med sovs', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-29',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 5,
		hint: {
			location: 'consumer',
			topic: 'food-service',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvilken type pølse får du serveret, hvis du bestiller en "nissearm"?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'En rød pølse', isSelected: false, isCorrect: true},
			{id: 2, text: 'En kålpølse', isSelected: false, isCorrect: false},
			{id: 3, text: 'En frankfurter', isSelected: false, isCorrect: false},
			{id: 4, text: 'En pølse i svøb', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-30',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 6,
		hint: {/// todo
			location: 'education',
			topic: 'cleanser',
			subtopic: null,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvem står for at afpudse grisens fedtende?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Pølsemageren', isSelected: false, isCorrect: false},
			{id: 2, text: 'Industrioperatøren', isSelected: false, isCorrect: false},
			{id: 3, text: 'Tarmrenseren', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-31',
		type: 'quiz',
		themeId: 5,
		challengeNumber: 7,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvilke medarbejdere på slagteriet skal have særligt fokus på fødevaresikkerhed og hygiejne?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Slagteren', isSelected: false, isCorrect: false},
			{id: 2, text: 'Alle', isSelected: false, isCorrect: true},
			{id: 3, text: 'Industrioperatøren', isSelected: false, isCorrect: false	},
		],
		clues: null
	},
	{
		id: 'quiz-32',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 1,
		hint: {
			location: 'consumer',
			topic: 'international',
			subtopic: 2,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvortil i Europa eksporterer Danmark mest gris?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Tyskland', isSelected: false, isCorrect: true},
			{id: 2, text: 'Sverige', isSelected: false, isCorrect: false},
			{id: 3, text: 'Polen', isSelected: false, isCorrect: false},
			{id: 4, text: 'Italien', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-33',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 2,
		hint: {
			location: 'butchery',
			topic: 'skank',
			subtopic: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvorfor er kogt grisetæer særligt populære hos kvinderne i Asien?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Fordi de smager godt', isSelected: false, isCorrect: false},
			{id: 2, text: 'Fordi de er nemme at tilberede', isSelected: false, isCorrect: false},
			{id: 3, text: 'Fordi de har et højt indhold af kollagen, som skulle give en smuk og glat hud', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-99',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 3,
		hint: {
			location: 'butchery',
			topic: 'head',
			subtopic: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'På restauranter i Kina spises grisehjernen som en delikatesse?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ja', isSelected: false, isCorrect: true},
			{id: 2, text: 'nej', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-34',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 4,
		hint: {
			location: 'consumer',
			topic: 'international',
			subtopic: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor mange lande eksporterer Danmark gris til på verdensplan?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Under 124 lande', isSelected: false, isCorrect: false},
			{id: 2, text: '124 lande', isSelected: false, isCorrect: false},
			{id: 3, text: 'Over 124 lande', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-35',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 5,
		hint: {
			location: 'consumer',
			topic: 'international',
			subtopic: 4,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'I hvilken verdensdel anses Jaka boven for at være eksklusiv og gives ofte som værtindegave?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Austalien', isSelected: false, isCorrect: false},
			{id: 2, text: 'USA', isSelected: false, isCorrect: false},
			{id: 3, text: 'Asien', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-36',
		type: 'quiz',
		themeId: 6,
		challengeNumber: 6,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 3,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'For 3-6000 år siden kom tamgrisen til Danmark - hvorfra kom den oprindeligt?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Østeuropa', isSelected: false, isCorrect: false},
			{id: 2, text: 'Sydeuropa', isSelected: false, isCorrect: true},
			{id: 3, text: 'Mellemøsten', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-37',
		type: 'quiz',
		themeId: 7,
		challengeNumber: 1,
		hint: {
			location: 'farm',
			topic: 'pig-transformation',
			subtopic: 3,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'For 1600 år siden vandrede grisene frit rundt i skovene - hvorfor satte man dem dengang i stald?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Det var mere praktisk ifm. fodring', isSelected: false, isCorrect: false},
			{id: 2, text: 'Så de ikke løb væk', isSelected: false, isCorrect: false},
			{id: 3, text: 'For at undgå afgift til kongen, for at lade grisene gå i skoven', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-38',
		type: 'quiz',
		themeId: 7,
		challengeNumber: 2,
		hint: {
			location: 'consumer',
			topic: 'food-service',
			subtopic: 1,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: `Hvor mange pølsevogne var der placeret rundt omkring i Danmark i 1970'erne?`,
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '700', isSelected: false, isCorrect: true},
			{id: 2, text: '450', isSelected: false, isCorrect: false},
			{id: 3, text: 'Over 1000', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-39',
		type: 'quiz',
		themeId: 7,
		challengeNumber: 3,
		hint: {
			location: 'consumer',
			topic: 'detail',
			subtopic: 3,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: `Hvor meget Jaka bov spiste danskerne i 2020?`,
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '125 ton', isSelected: false, isCorrect: true},
			{id: 2, text: 'Over 125 ton', isSelected: false, isCorrect: false},
			{id: 3, text: 'Under 125 ton', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-40',
		type: 'quiz',
		themeId: 7,
		challengeNumber: 4,
		hint: {
			location: 'butchery',
			topic: 'engine-room',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: `Engang var arbejdet på slagteriet et manuelt hårdt fysisk arbejde. I dag bruges der i langt højere grad robotter. Hvilken funktion har tredeler robotten?`,
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Afsaver spidsryggen fra midterstykket og deler i kam og bryst', isSelected: false, isCorrect: false},
			{id: 2, text: 'Deler den halverede gris i forende, midterstykke, skinke og bagtå', isSelected: false, isCorrect: true},
			{id: 3, text: 'Skærer tre koteletter ad gangen', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-41',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 1,
		hint: {
			location: 'education',
			topic: 'cleanser',
			subtopic: 4,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'I hvor mange lande kan man tage en tarmrenseruddannelse?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '5', isSelected: false, isCorrect: false},
			{id: 2, text: '1', isSelected: false, isCorrect: true},
			{id: 3, text: '4', isSelected: false, isCorrect: false},
			{id: 4, text: '2', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-42',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 2,
		hint: {
			location: 'butchery',
			topic: 'butcher',
			subtopic: 3,
			subtopic2: 1,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvad kendetegner en tarmrensers hverdag?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Kreativitet, samarbejde og langsomt tempo', isSelected: false, isCorrect: false},
			{id: 2, text: 'Fysisk arbejde, tålmodighed og højt tempo', isSelected: false, isCorrect: false},
			{id: 3, text: 'Struktur, høj hygiejne og præcision', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-43',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 5,
		hint: {
			location: 'education',
			topic: 'industrial-operator',
			subtopic: 1,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvor høj beskæftigelse er der blandt danske industrioperatører?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: '50%', isSelected: false, isCorrect: false},
			{id: 2, text: '30%', isSelected: false, isCorrect: false},
			{id: 3, text: '80%', isSelected: false, isCorrect: false},
			{id: 4, text: '98%', isSelected: false, isCorrect: true},
		],
		clues: null
	},
	{
		id: 'quiz-44',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 6,
		hint: {
			location: 'education',
			topic: 'industrial-operator',
			subtopic: 3,
			subtopic2: 2,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor mange steder i Danmark kan man uddanne sig til industrioperatør?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Man kan uddanne sig til industrioperatør 9 steder i Danmark', isSelected: false, isCorrect: true},
			{id: 2, text: 'Man kan uddanne sig til industrioperatør 2 steder i Danmark', isSelected: false, isCorrect: false},
			{id: 3, text: 'Man kan kun uddanne sig til industrioperatør i udlandet', isSelected: false, isCorrect: false},
			{id: 4, text: 'Man kan uddanne sig til industrioperatør 5 forskellige steder i Danmark.', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-45',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 8,
		hint: {
			location: 'education',
			topic: 'industrial-operator',
			subtopic: null,
			subtopic2: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Hvad er startlønnen for en nyuddannet industrioperatør',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ca. 25.000 kr. ', isSelected: false, isCorrect: false},
			{id: 2, text: 'Ca. 35.000 kr. ', isSelected: false, isCorrect: true},
			{id: 3, text: 'Ca. 31.000 kr.', isSelected: false, isCorrect: false},
			{id: 4, text: 'Ca. 22.000 kr. ', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-46',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 10,
		hint: {
			location: 'education',
			topic: 'butcher2',
			subtopic: null,
			subtopic2: null,
			popup: null,
			isInfoBoxText: true,
			isFunFact: false
		},
		question: {
			text: 'Hvor mange beskæftigede slagterimedarbejdere er der ca. i Danmark?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ca. 3000', isSelected: false, isCorrect: false},
			{id: 2, text: 'Ca. 6.392', isSelected: false, isCorrect: true},
			{id: 3, text: 'Ca. 2567', isSelected: false, isCorrect: false},
			{id: 4, text: 'Ca. 5654', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-47',
		type: 'quiz',
		themeId: 8,
		challengeNumber: 12,
		hint: {
			location: 'education',
			topic: 'butcher2',
			subtopic: null,
			popup: null,
			isInfoBoxText: false,
			isFunFact: true
		},
		question: {
			text: 'Gennemsnitslønnen for en slagterimedarbejder er samlet set?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Ca. 160,21 kr/t', isSelected: false, isCorrect: false},
			{id: 2, text: 'Ca. 230,21 kr/t', isSelected: false, isCorrect: false},
			{id: 3, text: 'Ca. 294, 21 kr/t', isSelected: false, isCorrect: true},
			{id: 4, text: 'Ca. 200,21 kr/t', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	{
		id: 'quiz-48',
		type: 'quiz',
		themeId: 7,
		challengeNumber: 5,
		hint: {
			location: 'consumer',
			topic: 'detail',
			subtopic: 2,
			subtopic2: 2,
			popup: 'video',
			isInfoBoxText: false,
			isFunFact: false
		},
		question: {
			text: 'Opskriften på frikadellen mødes første gang i kogebogen "En høyfornemme Madames Kaagebog" fra år 1704',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{id: 1, text: 'Sandt', isSelected: false, isCorrect: true},
			{id: 2, text: 'Falsk', isSelected: false, isCorrect: false},
		],
		clues: null
	},
	// {
	// 	id: 'quiz-7',
	// 	type: 'quiz',
	// 	themeId: 2,
	// 	challengeNumber: 3,
	// 	hint: null,
	// 	question: {
	// 		text: 'Hvad er verdensrekorden i baconspisning på 5 min.?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: '89 skiver', isSelected: false, isCorrect: false},
	// 		{id: 2, text: '142 skiver', isSelected: false, isCorrect: false},
	// 		{id: 3, text: '182 skiver', isSelected: false, isCorrect: true},
	// 	],
	// 	clues: null
	// },
	// {
	// 	id: 'quiz-3',
	// 	type: 'quiz',
	// 	themeId: 1,
	// 	challengeNumber: 6,
	// 	question: {
	// 		text: 'Fysisk forurening. Klik på billedet og find fem fejl:',
	// 		showNumberOfAnswers: false,
	// 		image: 'challenges/wrong-equipment.png',
	// 		showImageInPopup: true
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Fejl 1', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'Fejl 2', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Fejl 3', isSelected: false, isCorrect: true},
	// 		{id: 4, text: 'Fejl 4', isSelected: false, isCorrect: true},
	// 		{id: 5, text: 'Fejl 5', isSelected: false, isCorrect: true},
	// 		{id: 6, text: 'Fejl 6', isSelected: false, isCorrect: false},
	// 		{id: 7, text: 'Fejl 7', isSelected: false, isCorrect: false},
	// 		{id: 8, text: 'Fejl 8', isSelected: false, isCorrect: false},
	// 	],
	// 	clues: null
	// },
	// {
	// 	id: 'quiz-3',
	// 	type: 'quiz',
	// 	themeId: 1,
	// 	challengeNumber: 7,
	// 	question: {
	// 		text: 'Bakterier kan spredes på følgende måde:',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Gennem luften', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'Via mad og drikke', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'En som er usand', isSelected: false, isCorrect: false},
	// 	],
	// 	clues: null
	// },
];

export {
	quizzesData
};