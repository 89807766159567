import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './sort.scss';

const Sort = ({isPaused, selectedItemIndex, animation, sortData, handleSelectItem, handleSelectBox}) => {
	return (
		<div className={'Sort Sort--' + sortData.id + (isPaused ? ' Sort--paused' : '')}>

			{/* Sort header */}
			<div id="SortHeader" className="Sort-header">
				{/* Question */}
				<div className="Sort-question">{renderMarkdown(sortData.question.text)}</div>

				{/* Boxes */}
				<div id="SortBoxes" className={'Sort-boxes Sort-boxes--' + sortData.boxes.length}>
					{sortData.boxes.map((box, index) => {
						let boxClass = 'Sort-boxContainer Sort-boxContainer--' + (index + 1);
						if (box.id === 'true') boxClass = boxClass + ' Sort-boxContainer--green';
						if (box.id === 'false') boxClass = boxClass + ' Sort-boxContainer--red';
						return (
							<div 
								key={index}
								className={boxClass}
								onClick={() => {if (!isPaused) handleSelectBox(box.id);}}
							>
								<div className="Sort-boxText">{renderMarkdown(box.name)}</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Sort body */}
			<div id="SortBody" className="Sort-body">
				{/* Items */}
				<div id="SortItems" className="Sort-items">
					{sortData.items.map((item, index) => {
						let className = 'Sort-item';
						if (sortData.completed) {
							className = className + ' placed';
						} else {
							if (index === selectedItemIndex) {
								if (animation) {
									className = className + ' ' + animation;
								} else {
									className = className + ' selected';
								}
							} else {
								if (item.isPlaced) className = className + ' placed';	
							}
						}
						let bgImg = null;
						if (item.image) {
							bgImg = require('../../../assets/images/' + item.image).default;
							className = className + ' Sort-item--img';
						}
						return (
							<div 
								key={index}
								className={className}
								onClick={() => {if (!isPaused && item.isPlaced === false) handleSelectItem(index);}}
							>
								{bgImg && <img src={bgImg} alt={item.name} />}
								{renderMarkdown(item.name)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Sort.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	selectedItemIndex: PropTypes.number,
	animation: PropTypes.string,
	sortData: PropTypes.object.isRequired,
	handleSelectItem: PropTypes.func.isRequired,
	handleSelectBox: PropTypes.func.isRequired
};

export default Sort;