import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from 'components/app';

// First time the website is opened
// We get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// Everytime the window is resized
window.addEventListener('resize', function() {
	vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}, true);


ReactDOM.render(<App />, document.getElementById('root'));