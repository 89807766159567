import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import ReactPlayer from 'react-player';
import {renderMarkdown} from 'helpers/text-helper';
import './popup.scss';

const Popup = (props) => {
	let {
		showPopup,
		type,
		title,
		text,
		image,
		video,
		buttons,
		onClick,
		onClose,
		animate,
		popupRef
	} = props;

	let imageFile = null;
	if (image !== null && image.length > 0) {
		imageFile = require('assets/images/' + image).default;
	}

	let className = 'Popup Popup--' + type + (showPopup ? ' Popup--show' : '') + (animate ? ' animate' : '');
	if (type === 'image' && image !== null) {
		className += ' Popup--' + image.split('.')[0];
	}

	
	return (
		<div className={className}
			onClick={onClick ? onClick : null}
		>
			<ReactSVG src={require('../../assets/images/icon-x.svg').default}
				className="Popup-close" onClick={onClose}/>
			{title && <div className="Popup-title">{title}</div>}
			<div className="Popup-body">
				{text && <div ref={popupRef} className="Popup-text">{renderMarkdown(text)}</div>}
				{imageFile && <div className={'Popup-image'}>
					<img alt={image} src={imageFile} /></div>}
				{video && video.length > 0 &&
					<ReactPlayer width="100%" height="100%" controls={true} url={video} playing={showPopup}/>}
				{buttons && <div className="Popup-buttons">
					{buttons.map((button, index) => {
						return (
							<div
								key={index}
								className="Popup-button"
								onClick={() => {button.action(...button.params);}}
							>{button.text}</div>
						);
					})}
				</div>}
			</div>
		</div>
	);
};

Popup.defaultProps = {
	frameText: false,
	title: null,
	text: null,
	points: null,
	image: null,
	animate: false
};

Popup.propTypes = {
	showPopup: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	video: PropTypes.string,
	buttons: PropTypes.array,
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	animate: PropTypes.bool,
	popupRef: PropTypes.object
};

export default Popup;