const imagesData=[
	'bg-explore-farm.svg',
	'img-farm.svg',
	'bg-explore.svg',
	'img-farmer.svg',
	'bg-farmer-life.svg',
	'img-happens-at-butchery-selected.svg',
	'bg-login.svg',
	'img-happens-at-butchery.svg',
	'bg-pig-cuts.svg',
	'lever-gris.jpg',
	'mignon.jpg',
	'bg-teacher.svg',
	'minutstrimler.jpg',
	'butchery-topic-hover.svg',
	'pig-bronze-medal.svg',
	'eksport-gris.jpg',
	'pig-gold-medal.svg',
	'pig-silver-medal.svg',
	'fillet-royal.jpg',
	'pointer.svg',
	'flæskesteg-hel.jpg',
	'saute-skiver-gris.jpg',
	'hjerte-gris.jpg',
	'skank-gris.jpg',
	'icon-cuttingInstructions.svg',
	'slagter-påklædning-sikkerhedsudstyr.jpg',
	'icon-image.svg',
	'icon-loading.svg',
	'slagteriflow.jpg',
	'knive.jpg',
	'kniv-bur.jpg',
	'icon-recipe.svg',
	'stegeflæsk.jpg',
	'icon-tail-filled.svg',
	'svinebryst.jpg',
	'icon-tail.svg',
	'svinekam-uden-svær.jpg',
	'icon-video.svg',
	'icon-x.svg',
	'tema-hovedforløb.png',
	'img-butcher-selected.svg',
	'the-pig.svg',
	'img-butcher.svg',
	'uddannelses-forløb.png',
	'img-butchery.svg',
	'udskæring-gamle-dage.jpg',
	'img-engine-room-selected.svg',
	'vaskeplads-gamle-dage.jpg',
	'img-engine-room.svg',
	'icon-medal-bronze.svg',
	'icon-medal-silver.svg',
	'icon-medal-gold.svg',
	'pig/bryst-zoomed.svg',
	'pig/indmad-zoomed.svg',
	'pig/loin.svg',
	'pig/roast-pork.svg',
	'pig/skinke-zoomed.svg',
	'pig/bryst.svg',
	'pig/indmad.svg',
	'pig/pig.svg',
	'pig/skank-zoomed.svg',
	'pig/skinke.svg',
	'pig/fillet.svg',
	'pig/loin-zoomed.svg',
	'pig/ribs.svg',
	'pig/skank.svg'
];

export default imagesData;
