import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './pairs.scss';

const Pairs = ({isPaused, pairsData, handleSelectCard}) => {
	let numberOfPairsToFind = pairsData.columnA.filter((card) => {
		return (card.hasOwnProperty('correctConnectionId') && card.correctConnectionId !== null);
	}).length;
	return (
		<div className={'Pairs Pairs--' + pairsData.id + (isPaused ? ' Pairs--paused' : '')}>

			{/* Pairs header */}
			<div id="PairsHeader" className="Pairs-header">
				{/* Question */}
				<div className="Pairs-question">{renderMarkdown(pairsData.question.text)}</div>
			</div>

			{/* Pairs body */}
			<div id="PairsBody" className="Pairs-body">
				{/* Column A */}
				<div id="ColumnA" className="Pairs-column Pairs-column--a">
					{pairsData.columnA.map((card, index) => {
						let className = 'Pairs-card';
						if (card.isSelected) {
							className = className + ' selected';
						} else {
							if (card.connectedTo && !pairsData.completed) {
								let status = (card.connectedTo === card.correctConnectionId ? 'correct' : 'wrong');
								if (
									numberOfPairsToFind === 1 && 
									card.hasOwnProperty('correctConnectionId') && 
									card.correctConnectionId !== null
								) status = 'correct';
								className = className + ' ' + status;;
							}
						}
						if (pairsData.completed && card.hasOwnProperty('correctConnectionId')) {
							className = className + ' correct';
						}

						let bgImg = null;
						if (card.image) {
							bgImg = require('../../../assets/images/' + card.image).default;
							className = className + ' Pairs-card--bgImg';
						}
						return (
							<div 
								key={index} 
								className={className}
								style={bgImg ? {backgroundImage: 'url(' + bgImg + ')'} : null}
								onClick={() => {if (!isPaused) handleSelectCard('columnA', index);}}
							>{renderMarkdown(card.text)}</div>
						);
					})}
				</div>
				{/* Column B */}
				<div id="ColumnB" className="Pairs-column Pairs-column--b">
					{pairsData.columnB.map((card, index) => {
						let className = 'Pairs-card';
						if (card.isSelected) className = className + ' selected';
						if (pairsData.completed && 
							pairsData.columnA.some((cardA) => {return cardA.correctConnectionId === card.id;})
						) className = className + ' correct';
						if (!pairsData.completed && 
							pairsData.columnA.some((cardA) => {return cardA.connectedTo === card.id;})
						) {
							let cardA = pairsData.columnA.filter((cardA) => {return cardA.connectedTo === card.id;})[0];
							let status = (cardA.connectedTo === cardA.correctConnectionId ? 'correct' : 'wrong');
							if (
								numberOfPairsToFind === 1 && 
								pairsData.columnA.some((cardA) => {return cardA.correctConnectionId === card.id;})	
							) status = 'correct';
							className = className + ' ' + status;
						}

						let imgUrl = null;
						if (card.image) {
							imgUrl = require('../../../assets/images/challenges/' + card.image).default;
							className = className + ' bgImg';
						}
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {if (!isPaused) handleSelectCard('columnB', index);}}
							>{(imgUrl ? <img src={imgUrl} alt={'shape'}/> : renderMarkdown(card.text))}</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Pairs.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	pairsData: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired
};

export default Pairs;