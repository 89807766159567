import React from 'react';
import firebaseInit from 'firebase-init';
import LoginController from 'components/login/login-controller';
import {getScreenSize, getDeviceInfo, isMobileOrTablet} from 'helpers/device-helper';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';
import appConfig from 'config/app.config';
// import ImageLoader from 'components/image-loader/image-loader';
import './app.scss';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isInStandaloneMode: false,
			isTouchScreen: false,
			isMobile: false,
			isIos: false,
			isFullscreen: false,
			fullscreenAllowed: true,
			screenSize: {width: 0, height: 0},
			orientation: 'landscape',
			viewMode: 'landscape'
		};
		this.unsubscribe = null;
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleToggleFullscreen = this.handleToggleFullscreen.bind(this);
		this.updateFullscreenStatus = this.updateFullscreenStatus.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Initialize firestore */
		firebaseInit();

		/* Check if the device is run in stand alone mode */
		let isInStandaloneMode = 
		window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;

		/* Get device info */
		let [isTouchScreen, isMobile, isIos, screenSize, orientation] = getDeviceInfo();

		/* Viewmode depends on device */
		// let viewMode = (isTouchScreen ? 'portrait' : 'landscape');
		let viewMode = (isMobileOrTablet() ? 'portrait' : 'landscape');
		

		/* Landscape specific style */
		if (viewMode === 'landscape') document.body.style.overflow = 'hidden';

		/* Enable auto fullscreen on navigation */
		let fullscreenAllowed = (
			viewMode === 'landscape'
			|| (isMobile && !isIos && appConfig.env !== 'development')
		);

		/* Update state */
		this.setState({
			isLoading: false,
			isInStandaloneMode: isInStandaloneMode,
			isTouchScreen: isTouchScreen,
			isMobile: isMobile,
			isIos: isIos,
			screenSize: screenSize,
			orientation: orientation,
			viewMode: viewMode,
			isFullscreen: checkIfFullscreen(),
			fullscreenAllowed: fullscreenAllowed
		});

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', this.handleWindowResize, false);

		/* Add event listener for rotating tablet */
		document.addEventListener('orientationchange', this.handleOrientationChange, false);


		/* Add event listener for fullscreen status */
		if (fullscreenAllowed && document.addEventListener) {
			document.addEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('fullscreenchange', this.updateFullscreenStatus, false);
			document.addEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		document.removeEventListener('resize', this.handleWindowResize, false);
		document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('fullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
	}	

	/**
	 * User resized window
	 */
	handleWindowResize() {
		let [screenSize, orientation] = getScreenSize();
		this.setState({screenSize: screenSize, orientation: orientation});
	}

	/**
	 * User rotated tablet
	 */
	handleOrientationChange() {
		console.log('test');
	}

	/**
	 * Toggle full screen
	 */
	handleToggleFullscreen() {
		let isFullscreen = checkIfFullscreen();
		toggleFullscreen(!isFullscreen);
		this.setState({isFullscreen: !isFullscreen});
	}
	updateFullscreenStatus() {
		let isFullscreen = checkIfFullscreen();
		this.setState({isFullscreen: isFullscreen});
	}

	render () {
		return (
			<div id="app" className={'App App--' + this.state.orientation
				+ ' App--' + (isMobileOrTablet() ? 'mobileTablet' : '')}>
				<div id="appWrap" className="App-wrap">
					<LoginController />
				</div>
			</div>
		);
	}
}

export default App;
